import { Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../../../nestserver";
import { selectUser } from "../../../../redux/slices/auth.slice";
import StudentEvaluation from "../courseboard/StudentEvaluation";

const ManageAssignment = () => {
  const { Option } = Select;
  const user = useSelector(selectUser);
  const [subjectId, setSubjectId] = useState();
  const [classSubjectData, setClassSubjectData] = useState([]);

  const handleSelectSubjectChange = (classId) => {
    setSubjectId(classId);
  };

  const fetchClassSubject = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/students/" + user?._id + "/subject-teacher/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    setClassSubjectData(res?.data);
    return res.data;
  };
  const classSubjectDetails = useQuery(
    ["students", user?._id],
    fetchClassSubject,
    {
      enabled: !!user?._id,
    }
  );

  async function getSubject() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/subjects/" + subjectId,
      data: {},
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const subject = useQuery(["subject", subjectId], getSubject);

  return (
    <div>
      <div className="my-8 px-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl pb-4 text-orange-500 font-semibold">
            Manage Assignment
          </div>
          <div className="flex gap-4">
            <div className="flex flex-col w-310">
              <h3 className="text-base pb-3"> Subjects*</h3>
              <Select
                showSearch
                showArrow
                value={subjectId}
                placeholder="Select Subject"
                className="w-full bg-gray-300"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectSubjectChange(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {classSubjectData &&
                  classSubjectData?.result?.subjects?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data?.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="">
            {subjectId && (
              <StudentEvaluation
                subjectDetails={subject?.data}
                isExam={false}
                isAssignment={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAssignment;
