// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   user: undefined,
// }

// const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {
//     loginUser(state, action) {
//       state.user = action.payload;
//     },
//     logoutUser(state) {
//       state.user = null;
//     }
//   }
// })

// // actions
// export const { loginUser, logoutUser } = authSlice.actions;

// // selectors
// export const selectUser = (state) => state.auth.user;

// // reducer
// export default authSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   user: JSON.parse(localStorage.getItem("loginUser")) || {}, // Local storage se initial value load
// };

// const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {
//     loginUser(state, action) {
//       state.user = action.payload;
//       localStorage.setItem("loginUser", JSON.stringify(action.payload)); // Local storage mein user save karo
//     },
//     logoutUser(state) {
//       state.user = null;
//       localStorage.removeItem("loginUser"); // Local storage se user clear karo
//     },
//   },
// });

// // actions
// export const { loginUser, logoutUser } = authSlice.actions;

// // selectors
// export const selectUser = (state) => state.auth.user;

// // reducer
// export default authSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: (() => {
    const savedUser = localStorage.getItem("loginUser");
    try {
      return savedUser ? JSON.parse(savedUser) : null; // Parse only if not null
    } catch {
      return null; // If parsing fails, return null
    }
  })(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser(state, action) {
      state.user = action.payload;
      localStorage.setItem("loginUser", JSON.stringify(action.payload)); // Save user to localStorage
    },
    logoutUser(state) {
      state.user = null;
      localStorage.removeItem("loginUser"); // Clear user from localStorage
    },
  },
});

// actions
export const { loginUser, logoutUser } = authSlice.actions;

// selectors
export const selectUser = (state) => state.auth.user;

// reducer
export default authSlice.reducer;
