import { Button, DatePicker, Form, Select, message } from "antd";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import AutosizeInput from "react-input-autosize";
import { useQueryClient } from "react-query";
import { useReactToPrint } from "react-to-print";
import { cloudFrontUrl, serverUrl } from "../../../../../../nestserver";
import moment from "moment";
const { Option } = Select;

const ConductCertificate = ({
  getAdmin,
  getStudentTemplate,
  formType,
  studentId,
  templateId,
}) => {
  const pdfRef = useRef();
  const componentRef = useRef();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [data, setData] = useState({
    name: "",
    parent_name: "",
    address: "",
    district: "",
    year: "",
    conduct: "",
  });

  const downloadPDF = async () => {
    const input = pdfRef.current;

    // Wait for all images to load
    await Promise.all(
      Array.from(input.querySelectorAll("img")).map((img) => {
        if (img.complete) {
          return Promise.resolve();
        } else {
          return new Promise((resolve, reject) => {
            img.onload = () => {
              resolve();
            };
            img.onerror = (error) => {
              console.error("Image failed to load:", img.src, error);
              reject();
            };
          });
        }
      })
    );

    // Add a small delay to ensure images are rendered
    await new Promise((resolve) => setTimeout(resolve, 100));

    html2canvas(input, {
      scale: 2,
      useCORS: true,
      allowTaint: false,
      // proxy: "https://d3n2ne2tgz4s0t.cloudfront.net",
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0); // Maximum quality
      const pdf = new jsPDF("p", "mm", "a4", true);

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;

      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio,
        undefined,
        "FAST" // Change this to 'SLOW' or 'FAST' depending on the quality/speed trade-off you prefer
      );

      pdf.save("transferCertificate.pdf");
    });
  };

  //print
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onFinish = (values) => {
    const payload = {
      form_type: formType ? formType : null,
      student: studentId ? studentId : null,
      form_template: templateId ? templateId : null,
      fields: [],
      ...data,
      declaration_message: null,
    };
    axios
      .post(serverUrl + "/form-template/", payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        queryClient?.invalidateQueries(["form-template"]);
        message.success(res?.data?.result?.message);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error?.errors);
      });
  };
  const updateDataForm = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };
  useEffect(() => {
    if (getStudentTemplate) {
      setData({
        name: getStudentTemplate?.name,
        parent_name: getStudentTemplate?.parent_name,
      });
    }
  }, [getStudentTemplate]);
  return (
    <>
      <div
        className="tc-doc"
        ref={(el) => {
          componentRef.current = el;
          pdfRef.current = el;
        }}
      >
        <div className="p-8">
          <div
            style={{ borderRadius: "10px", color: "#616161" }}
            className=" bg-white drop-shadow-md p-8  "
          >
            <div className="border ">
              <div className="upper-part ">
                <div className="flex flex-col">
                  <h3 className="text-base pb-3 flex items-center">
                    {" "}
                    <div className="relative w-20 h-20 rounded-full  border-8 border-white">
                      <img
                        className="w-full h-full rounded-full border"
                        src={
                          getStudentTemplate?.school?.logo
                            ? `${cloudFrontUrl}/common/${getStudentTemplate?.school?.logo}`
                            : "https://ui-avatars.com/api/?name=" +
                              (getStudentTemplate?.school?.name
                                ? getStudentTemplate?.school?.name
                                : getStudentTemplate?.school?.name)
                        }
                        alt="banner"
                      />
                    </div>
                  </h3>
                </div>
                <div className="flex flex-col gap-2" style={{ width: "400px" }}>
                  <p className="text-base font-semibold pb-2 capitalize">
                    {getStudentTemplate?.school &&
                      getStudentTemplate?.school?.name}
                  </p>
                  <p className="text-base font-semibold capitalize">
                    {getStudentTemplate?.school &&
                      `${getStudentTemplate?.school?.address}, ${getStudentTemplate?.school?.city}, ${getStudentTemplate?.school?.state}, ${getStudentTemplate?.school?.pincode}`}
                  </p>
                </div>
              </div>
              <div className="lower-part p-4">
                <h3 className="text-xl font-semibold text-center pb-4">
                  Conduct Certificate
                </h3>
                <h6 className="text-base">
                  {" "}
                  <span className="text-base font-semibold">
                    Aff No./School Code :
                  </span>{" "}
                  {getStudentTemplate?.school?.refId}
                </h6>
                <div className="flex justify-between items-center pt-4 pb-8">
                  <h6 className="text-base">
                    <span className="text-base font-semibold">
                      File No :{" "}
                      {getStudentTemplate?.file_number &&
                        getStudentTemplate?.file_number}
                    </span>{" "}
                  </h6>
                  <h6 className="text-base">
                    <p className="text-base font-semibold">
                      Admission No :{" "}
                      {getStudentTemplate?.admission_number
                        ? getStudentTemplate?.admission_number
                        : "--------"}
                    </p>
                  </h6>
                </div>
                <div className="study-certificate">
                  <Form form={form} name="dynamic_rule" autoComplete="off">
                    <div>
                      This is to certify that Mr/Miss &nbsp;
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("name", e.target.value);
                        }}
                        value={data?.name}
                        inputClassName="custom-input"
                      />
                      &nbsp; son/daughter of Mr/Mrs &nbsp;{" "}
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("parent_name", e.target.value);
                        }}
                        value={data?.parent_name}
                        inputClassName="custom-input"
                      />
                      &nbsp; House &nbsp;{" "}
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("address", e.target.value);
                        }}
                        value={data?.address}
                        inputClassName="custom-input"
                      />
                      post&nbsp;
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("district", e.target.value);
                        }}
                        value={data?.district}
                        inputClassName="custom-input"
                      />
                      &nbsp;district is known to me personally for the last
                      &nbsp;
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("year", e.target.value);
                        }}
                        value={data?.year}
                        inputClassName="custom-input "
                      />
                      Year (s). . His/Her Conduct and character are{" "}
                      <AutosizeInput
                        type="text"
                        onChange={(e) => {
                          updateDataForm("conduct", e.target.value);
                        }}
                        value={data?.conduct}
                        inputClassName="custom-input"
                      />
                    </div>
                    <div className="pt-4">He/She is not related to me.</div>
                    <div className=" pt-10 pb-10">
                      <div className="flex justify-between items-center download-pdf">
                        <div className="flex gap-4  items-center">
                          <h3 className="text-base font-semibold pl-30px">
                            Date{" "}
                            {getStudentTemplate?.generated_date &&
                              moment(getStudentTemplate?.generated_date).format(
                                "YYYY-MM-DD"
                              )}
                            <DatePicker
                              format="DD-MM-YYYY"
                              placeholder="Select date"
                              disabledDate={(current) =>
                                current && current > moment().endOf("day")
                              }
                              suffixIcon={null}
                              className="disabled-inputt"
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </h3>
                        </div>
                        <div className="flex gap-4 items-center">
                          <h3 className="text-base font-semibold pr-30px">
                            Signature
                          </h3>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-10">
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          onClick={onFinish}
        >
          Submit
        </Button>
      </div>
      {getStudentTemplate?.file_number && (
        <div className="flex justify-center gap-4  download-pdf">
          <Button size="large" onClick={handlePrint}>
            Print
          </Button>
          <Button size="large" onClick={downloadPDF}>
            Download
          </Button>
        </div>
      )}
    </>
  );
};

export default ConductCertificate;
