import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Comment,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Tooltip,
  message,
} from "antd";
import Button from "antd-button-color";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineComment, AiOutlineSend } from "react-icons/ai";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import { cloudFrontUrl } from "../../nestserver";
import { selectUser } from "../../redux/slices/auth.slice";
import {
  useCreateComment,
  useDeleteComment,
  useUpdateComment,
} from "../../services/comment.service";
import {
  useDeleteDiscussion,
  useUpdateDiscussion,
} from "../../services/discussion.service";
import ImageUploadButton from "../inputs/ImageUploadButton";

/* -------------------------------------------------------------------------- */
/*                              Preview Componet                              */
/* -------------------------------------------------------------------------- */
function Preview({ data, onDelete, progress }) {
  return (
    <div
      style={{
        borderRadius: "8px",
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "#000",
          borderRadius: "8px",
          height: "100%",
          width: "100%",
          opacity: 0.5,
        }}
      ></div>
      <Row
        justify="space-around"
        align="middle"
        style={{ height: "100%", width: "100%", padding: "10px" }}
      >
        <Button type="warning" icon={<EditOutlined />}>
          Change
        </Button>
      </Row>
    </div>
  );
}

function DiscussionCard({
  data,
  enableDelete = false,
  enableComment = true,
  enableDeleteComment = false,
}) {
  const queryClient = useQueryClient();
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const [discussionForm] = Form.useForm();

  const user = useSelector(selectUser);
  const [showComments, setShowComments] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState();

  const deleteDiscussionMutation = useDeleteDiscussion();
  const updateDiscussionMutation = useUpdateDiscussion();
  const createCommentMutation = useCreateComment();
  const deleteCommentMutation = useDeleteComment();
  const updateCommentMutation = useUpdateComment();

  const confirmDelete = () => {
    confirm({
      title: "Do you want to delete?",
      icon: <ExclamationCircleOutlined />,
      //   content: 'Some descriptions',
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteDiscussionMutation.mutate(data?._id, {
          onSuccess: () => {
            message.success("Discussion Deleted");
            queryClient.invalidateQueries("discussions");
          },
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Add Comment                                */
  /* -------------------------------------------------------------------------- */
  function addComment(values) {
    createCommentMutation.mutate(
      {
        discussion: data?._id,
        comment: values.comment?.trim(),
        user: user?._id,
      },
      {
        onSuccess: () => {
          message.success("Comment Added");
          queryClient.invalidateQueries("discussions");
          setOpenModal(false);
          form.resetFields();
        },
        onError: (error) => {
          message.error("Something went wrong");
        },
      }
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                                 Delete Comment                                */
  /* -------------------------------------------------------------------------- */
  function deleteComment(id) {
    deleteCommentMutation.mutate(id, {
      onSuccess: () => {
        message.success("Comment Deleted");
        queryClient.invalidateQueries("discussions");
        setOpenModal(false);
      },
      onError: (error) => {
        message.error("Something went wrong");
      },
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                                 Delete Comment                                */
  /* -------------------------------------------------------------------------- */
  function updateComment(values, id) {
    updateCommentMutation.mutate(
      [
        id,
        {
          comment: values.comment?.trim(),
        },
      ],
      {
        onSuccess: () => {
          message.success("Comment Deleted");
          queryClient.invalidateQueries("discussions");
          setOpenModal(false);
          form.resetFields();
        },
        onError: (error) => {
          message.error("Something went wrong");
        },
      }
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                              Update Discussion                             */
  /* -------------------------------------------------------------------------- */
  const updateDiscussion = (values) => {
    values["image"] = image;

    updateDiscussionMutation.mutate([data?._id, values], {
      onSuccess: () => {
        message.success("Discussion Updated");
        setEdit(false);
        discussionForm.resetFields();
        queryClient.invalidateQueries("discussions");
      },
    });
  };

  useEffect(() => {
    form.resetFields();
    discussionForm.resetFields();
    setImage(data?.image);
  }, [openModal, edit]);

  const actions = (data) => {
    return [
      data?.user?._id === user?._id && (
        <>
          <Tooltip key="comment-basic-edit" title="Edit">
            <span
              onClick={() => {
                setOpenModal(data);
              }}
              className="flex items-center space-x-2"
            >
              <EditOutlined />
              <span className="comment-action">Edit</span>
            </span>
          </Tooltip>
        </>
      ),
      <Tooltip key="comment-basic-delete" title="Delete">
        <Popconfirm
          placement="bottomLeft"
          title={`Are you sure you want to delete this comment?`}
          onConfirm={() => deleteComment(data?._id)}
          okText="Yes"
          cancelText="No"
        >
          <span className="flex items-center space-x-2">
            <DeleteOutlined />
            <span className="comment-action">Delete</span>
          </span>
        </Popconfirm>
      </Tooltip>,
    ];
  };


  return (
    <>
      <div className=" bg-white rounded-xl">
        {data && (
          <div className="py-5 px-5 ">
            <div className="flex  justify-between">
              <div
                style={{ color: "#616161" }}
                className="flex flex-row space-x-3 text-left text-lg px-5"
              >
                <div>
                  {/* <Avatar
                    size={50}
                    src={
                      data?.user
                        ? data?.user[
                            data?.user?.role === "instructor"
                              ? "teacher"
                              : data?.user?.role
                          ]?.profile_image
                          ? cloudFrontUrl +
                            "/" +
                            data?.user?._id +
                            "/" +
                            data?.user[
                              data?.user?.role === "instructor"
                                ? "teacher"
                                : data?.user?.role
                            ]?.profile_image
                          : "https://ui-avatars.com/api/?name=" +
                            data?.user[
                              data?.user?.role === "instructor"
                                ? "teacher"
                                : data?.user?.role
                            ]?.first_name +
                            " " +
                            data?.user[
                              data?.user?.role == "instructor"
                                ? "teacher"
                                : data?.user?.role
                            ]?.last_name
                        : "https://ui-avatars.com/api/?name=A"
                    }
                    alt="logo"
                  ></Avatar> */}
                  <Avatar
                    size={50}
                    src={
                      data?.user?.teacher?.profile_image
                        ? cloudFrontUrl +
                          "/" +
                          data?.user._id +
                          "/" +
                          data.user?.teacher?.profile_image
                        : "https://ui-avatars.com/api/?name=" +
                          (data.user?.teacher?.first_name +
                            " + " +
                            data.user?.teacher?.last_name)
                    }
                    alt="logo"
                  ></Avatar>
                </div>
                <div className="flex flex-col">
                  <div style={{ color: "#09617E" }} className="text-xl">
                    {
                      data?.user[
                        data?.user?.role == "instructor"
                          ? "teacher"
                          : data?.user?.role
                      ]?.first_name
                    }
                  </div>
                  <div>
                    {moment(data?.updatedAt).format("MMM Do YYYY, h:mm a")}
                  </div>
                </div>
              </div>
              {((enableDelete && data.user?._id === user?._id) ||
                user?.role === "admin" ||
                user?.role === "instructor") && (
                <div className="flex items-center space-x-4">
                  {data.user?._id === user?._id ? (
                    <EditOutlined
                      onClick={() => setEdit(true)}
                      className=" text-gray-400 hover:text-yellow-500 cursor-pointer"
                    />
                  ) : (
                    ""
                  )}

                  <AiFillDelete
                    className="text-xl text-gray-400 hover:text-red-500 cursor-pointer"
                    onClick={confirmDelete}
                  />
                </div>
              )}
            </div>

            <div className="text-lg text-left px-5 py-4">
              <p
                style={{
                  wordWrap: "break-word",
                }}
              >
                {data?.details}
              </p>
              {data?.image && (
                <div className="w-full h-80 pt-4 ">
                  <img
                    className="w-full h-80 object-cover rounded-lg"
                    src={
                      data?.image
                        ? cloudFrontUrl +
                          "/" +
                          data?.user?._id +
                          "/" +
                          data?.image
                        : ""
                    }
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="border-t pt-4 divide-y">
              <div className="flex items-center justify-around pb-4">
                <div
                  onClick={() => setShowComments(!showComments)}
                  className="inline-flex items-center space-x-2 cursor-pointer text-gray-500 hover:text-primary-500"
                >
                  <AiOutlineComment size={28} className="text-gray-500" />
                  <div>Comments ({data?.comments?.length ?? 0})</div>
                </div>
                {user.role !== "parent" && enableComment && (
                  <div
                    onClick={() => {
                      setOpenModal(true);
                      form?.resetFields();
                    }}
                    className="inline-flex items-center space-x-2 cursor-pointer text-gray-500 hover:text-primary-500"
                  >
                    <AiOutlineSend size={28} />
                    <div>Post a comment</div>
                  </div>
                )}
              </div>
              {showComments && (
                <div className="p-6 ">
                  {data?.comments?.length > 0 ? (
                    data?.comments?.map((comment) => (
                      <Comment
                        actions={
                          ((enableDeleteComment &&
                            comment.user?._id === user?._id) ||
                            user?.role === "admin" ||
                            user?.role === "instructor") &&
                          actions(comment)
                        }
                        key={comment?._id}
                        author={
                          <a>
                            {
                              comment?.user[
                                comment?.user?.role === "instructor"
                                  ? "teacher"
                                  : comment?.user?.role
                              ]?.first_name
                            }{" "}
                            {
                              comment?.user[
                                comment?.user?.role === "instructor"
                                  ? "teacher"
                                  : comment?.user?.role
                              ]?.last_name
                            }
                          </a>
                        }
                        avatar={
                          <Avatar
                            src={`${cloudFrontUrl}/${comment?.user?._id}/${
                              comment?.user[
                                comment?.user?.role === "instructor"
                                  ? "teacher"
                                  : comment?.user?.role
                              ]?.profile_image
                            }`}
                            alt={
                              comment?.user[
                                comment?.user?.role === "instructor"
                                  ? "teacher"
                                  : comment?.user?.role
                              ]?.first_name
                            }
                            icon={<UserOutlined />}
                          />
                        }
                        content={<p>{comment?.comment}</p>}
                        datetime={
                          <Tooltip
                            title={moment(comment?.updatedAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          >
                            <span>{moment(comment?.updatedAt).fromNow()}</span>
                          </Tooltip>
                        }
                      />
                    ))
                  ) : (
                    <div className="text-center text-gray-500">
                      No comments yet
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <Modal
          title={"Post your comment"}
          visible={openModal}
          onOk={() => {
            form.submit();
          }}
          onCancel={() => {
            setOpenModal(false);
            form.resetFields();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={
              openModal?._id ? { comment: openModal?.comment } : {}
            }
            onFinish={(values) =>
              openModal?._id
                ? updateComment(values, openModal?._id)
                : addComment(values)
            }
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Enter your comment",
                },
              ]}
              name="comment"
            >
              <Input.TextArea rows={4}>Enter your comment</Input.TextArea>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Edit Discussion"
          visible={edit}
          onCancel={() => setEdit(false)}
          okText="Save"
          onOk={() => discussionForm.submit()}
          destroyOnClose={true}
          afterClose={() => {
            discussionForm.resetFields();
            setImage(null);
          }}
        >
          <div className="bg-gray-100 mb-3">
            <ImageUploadButton
              folder={data?.user?._id}
              ratio={9 / 2.5}
              onUpload={(e) => setImage(e)}
            >
              <div>
                <Image
                  src={
                    image
                      ? cloudFrontUrl + "/" + data?.user?._id + "/" + image
                      : "https://via.placeholder.com/900x250/ddd/aaa?text=Add+Banner"
                  }
                  fallback={
                    "https://via.placeholder.com/900x250/ddd/aaa?text=Add+Banner"
                  }
                  preview={{
                    visible: false,
                    mask: <Preview />,
                  }}
                />
              </div>
            </ImageUploadButton>
            {image && (
              <div className="flex items-center justify-center py-4">
                <Button type="danger" onClick={() => setImage(null)}>
                  Remove Image
                </Button>
              </div>
            )}
          </div>
          <div>
            <Form
              name="basic"
              initialValues={{ details: data?.details }}
              onFinish={updateDiscussion}
              form={discussionForm}
            >
              <Form.Item
                name="details"
                rules={[
                  {
                    required: true,
                    message: "Please input the content",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Description"
                  className="w-full border focus:outline-none resize-none"
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default DiscussionCard;
