import { Button, Form, Input, Modal, Select, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import StudentFeDetails from "../../../../admin/pages/components/managefee/StudentFeeDetails";
import ViewTransactionHistory from "../../../../admin/pages/components/managefee/ViewTransactionHistory";
const ViewFeeDetails = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const isParent = true;
  const [OpenTransactionPopup, setOpenTransactionPopup] = useState(false);
  const [openTransactionHistory, setOpenTransactionHistory] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState();
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [selectedStudentResponse, setSelectedStudentResponse] = useState([]);
  const [year, setYear] = useState("");
  const [isYear, setIsYear] = useState();

  const handleYearChange = (e) => {
    setYear(e.target.value);
    setIsYear(e.target.value?.toString().slice(0, 4).length === 4);
  };

  async function fetchStudent() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/students/by-parent/`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data.result;
  }
  const students = useQuery("students/by-parent", fetchStudent);

  const handleSelectChange = (studentId) => {
    if (studentId) {
      setSelectedStudentId(studentId);

      const fetchClassStudents = async () => {
        if (year) {
          const res = await axios({
            method: "get",
            url: `${serverUrl}/student-fee/${studentId}?year=${year}`,
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          });
          return res.data;
        }
      };
      fetchClassStudents()
        .then((response) => {
          form.resetFields(["studentId", "class", "parent", "phone"]);

          setSelectedStudentResponse(response?.result);
          form.setFieldsValue({
            studentId: response?.result?.[0]?.student?.unique_id,
            class: response?.result?.[0]?.class?.name,
            parent: `${response?.result?.[0]?.student?.parent?.first_name} ${response?.result?.[0]?.student?.parent?.last_name}`,
            phone: response?.result?.[0]?.student?.parent?.phone,
          });
        })
        .catch((err) => {
          message.error(err?.response?.data?.error?.errors);
          form.resetFields(["studentId", "class", "parent", "phone"]);
          setSelectedStudentResponse();
        });
    }
  };
  useEffect(() => {
    if (!isYear) {
      setSelectedStudentResponse();
      form.resetFields(["name", "studentId", "class", "parent", "phone"]);
    }
  }, [!isYear]);


  const fetchStudentFeeHistory = async () => {
    const studentId = selectedStudentResponse?.[0]?.student?._id;

    if (studentId) {
      try {
        const res = await axios({
          method: "get",
          url: `${serverUrl}/student-fee/${studentId}/transaction-history`,
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        });
        return res.data;
      } catch (error) {
        console.error("Error fetching student fee history:", error);
        throw error;
      }
    }
    return null;
  };

  useEffect(() => {
    const studentId = selectedStudentResponse?.[0]?.student?._id;

    if (studentId) {
      fetchStudentFeeHistory()
        .then((response) => {
          if (response) {
            setTransactionHistory(response?.result);
          }
        })
        .catch((err) => {
          console.error("Error in useEffect:", err);
        });
    }
  }, [selectedStudentResponse?.[0]?.student?._id]);

  return (
    <div className="student-form-fee">
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="text-2xl text-orange-500 font-semibold pb-4">
            Manage Fee
          </div>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div className="form-grid">
              <Form.Item
                label="Year"
                name="year"
                rules={[
                  {
                    pattern: /^\d{4}$/,
                    message: "Enroll year must be a 4-digit number!",
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 4)}
              >
                <Input
                  placeholder="Please enter year to select student"
                  onChange={handleYearChange}
                  type="number"
                  min={0}
                  maxLength={4}
                />
              </Form.Item>
              {year && isYear && (
                <>
                  <Form.Item label="Name" name="name">
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select name"
                      className="w-full bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value) => handleSelectChange(value)}
                    >
                      {students &&
                        students?.data?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {`${data?.first_name} ${data?.last_name}`}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Student Id" name="studentId">
                    <Input readOnly />
                  </Form.Item>
                </>
              )}
            </div>
            {year && isYear && (
              <div className="form-grid">
                <Form.Item label="Class" name="class">
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="Parent" name="parent">
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                  <Input readOnly />
                </Form.Item>
              </div>
            )}
          </Form>

          <div className="flex justify-end pt-5 items-center">
            <Button
              class
              onClick={() => {
                setOpenTransactionHistory(true);
              }}
            >
              View History
            </Button>
          </div>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div>
              <StudentFeDetails
                feeResponse={isYear && selectedStudentResponse}
                isParent={isParent}
                classId={isYear && selectedStudentResponse?.[0]?.class?._id}
                studentId={isYear && selectedStudentResponse?.[0]?.student?._id}
              />
            </div>
          </Form>
        </div>
      </div>

      <Modal
        width={360}
        layout="vertical"
        visible={OpenTransactionPopup}
        onOk={() => setOpenTransactionPopup(false)}
        onCancel={() => {
          setOpenTransactionPopup(false);
        }}
        footer={null}
      >
        <div className="pt-4">pay</div>
      </Modal>
      <Modal
        width={1100}
        layout="vertical"
        visible={openTransactionHistory}
        onOk={() => setOpenTransactionHistory(false)}
        onCancel={() => {
          setOpenTransactionHistory(false);
        }}
        footer={null}
      >
        <ViewTransactionHistory
          transactionHistory={isYear && transactionHistory}
        />
      </Modal>
    </div>
  );
};

export default ViewFeeDetails;
