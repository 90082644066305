import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import CreateExamForm from "../../../components/forms/CreateExamForm";
import ExamTable from "../../../components/tables/ExamTable";
import EditExam from "./EditExam";

function Exam({
  subjectId = null,
  classId = null,
  canCreate = false,
  onCallback = console.log,
}) {
  const [createexam, setCreateExam] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selected) {
      onCallback(false);
    } else {
      onCallback(true);
    }
  }, [selected]);

  return (
    <>
      {selected ? (
        <>
          <EditExam id={selected?.id} onBack={() => setSelected(null)} />
        </>
      ) : (
        <div className="py-10 ">
          <div
            style={{ borderRadius: "10px", color: "#616161" }}
            className=" bg-white drop-shadow-md w-full "
          >

            <div className="pt-8">
              <div className="h-16">
                <div
                  style={{ backgroundColor: "#09617E" }}
                  className="uppercase rounded-tl-xl text-white rounded-tr-xl h-full 
                            font-normal text-base flex items-center justify-center relative"
                >
                  <h1 className="text-white font-bold">EXAMS</h1>
                  {canCreate && (
                    <div
                      className="absolute  items-center justify-center top-1/4 right-6"
                    >
                      <Button
                        onClick={() => setCreateExam(true)}
                        className="w-40"
                        type="secondary"
                      >
                        Create Exam
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <Modal
                title="Create Exam"
                width={800}
                visible={createexam}
                onOk={() => setCreateExam(false)}
                onCancel={() => setCreateExam(false)}
                footer={null}
              >
                <div>
                  <CreateExamForm
                    subject_id={subjectId}
                    class_id={classId}
                    onCancel={() => setCreateExam(false)}
                  />
                </div>
              </Modal>

              <div>
                <ExamTable
                  onClick={(data) => setSelected(data)}
                  subjectId={subjectId}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Exam;
