import { Button, Checkbox, message } from "antd";
import axios from "axios";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { serverUrl } from "../../../nestserver";

const SchoolMigration = () => {
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState(null);
  const [uploadCheckbox, setUploadCheckbox] = useState(false);
  const [uploadDocDetails, setUploadDocDetails] = useState();
  const [isUploaded, setIsUploaded] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const isExcelFile =
      selectedFile?.type === "application/vnd.ms-excel" ||
      selectedFile?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isExcelFile) {
      message.error("Please upload an Excel file (*.xlsx or *.xls)");
      return;
    }
    setFile(selectedFile);
    const data = new FormData();
    data.append("files", selectedFile);
    data.append("folder", "docs");
    setFormData(data);
     setIsUploaded(true);
    message.success("uploaded");
  };

  const handleUpload = () => {
    document.getElementById("fileInput").click();
  };

  const handleConfirm = async () => {
    if (!formData || !file) {
      message.error("Please upload a file first");
      return;
    }
    if (uploadDocDetails) {
      message.error("File already uploaded");
      return;
    }

    try {
      const res = await axios.post(serverUrl + "/uploads/docs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadDocDetails(res.data);
      setIsConfirmed(true);
      // setFile(null);
      // setFormData(null);
      message.success("Uploaded file successfully");
    } catch (error) {
      message.error("Uploading failed");
    }
  };

  const handleExecute = async () => {
    const payload = {
      school: id ? id : "",
      file: uploadDocDetails?.[0],
    };
    if (!uploadDocDetails) {
      file
        ? message.error("Please Click on confirm")
        : message.error("Please upload a file first");
      return;
    }
    try {
      await axios.post(serverUrl + "/users/bulk-create", payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      message.success("Uploaded file successfully Executed");
      setUploadCheckbox(false);
      setFile(null);
      setFormData(null);
      setUploadDocDetails("");
    } catch (err) {
      message.error(err?.response?.data?.error?.errors[0]);
    }
  };
  const handleDownload = async (e) => {
    e.preventDefault();
    axios({
      url: serverUrl + "/students/excel-format",
      method: "GET",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        responseType: "blob",
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((response) => {
      saveAs(response.data, "studentDetails-sheet");
    });
  };
  return (
    <div className="pt-8">
      <div
        style={{ borderRadius: "10px", color: "#616161" }}
        className="bg-white drop-shadow-md p-8"
      >
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="fileInput"
        />
        <div className="text-base pb-4">
          <span className="font-semibold">Step 1 :</span>
          {""} Download the doc
          <span className="pl-4">
            <Button onClick={handleDownload} size="small" type="primary">
              Download
            </Button>
          </span>
        </div>{" "}
        <div className="text-base pb-4">
          <span className="font-semibold">Step 2 :</span>
          {""} Ensure the subjects,classes,instructors are created.
        </div>{" "}
        <div className="text-base">
          <span className="font-semibold">Step 3 :</span>
          {""} Ensure the data entered in excel match the data in application
          read content.
        </div>
        <div className="pt-8 text-center">
          <Checkbox
            onChange={(e) => setUploadCheckbox(e.target.checked)}
            checked={uploadCheckbox}
          >
            Agree
          </Checkbox>
        </div>
        <div className="flex gap-10 pt-10 justify-center">
          <div
            className="flex flex-col gap-3 justify-center items-center"
            style={{ width: "100px" }}
          >
            <p className="flex justify-center items-center w-10 h-10 border rounded-full bg-primary text-white">
              1
            </p>
            <Button
              onClick={handleUpload}
              disabled={!uploadCheckbox || isUploaded}
            >
              Upload
            </Button>
          </div>
          <div
            className="flex flex-col gap-3 justify-center items-center"
            style={{ width: "100px" }}
          >
            <p className="flex justify-center items-center w-10 h-10 border rounded-full bg-primary text-white">
              2
            </p>
            <Button
              onClick={handleConfirm}
              disabled={!uploadCheckbox || !isUploaded || isConfirmed}
            >
              Confirm
            </Button>
          </div>
          <div
            className="flex flex-col gap-3 justify-center items-center"
            style={{ width: "100px" }}
          >
            <p className="flex justify-center items-center w-10 h-10 border rounded-full bg-primary text-white">
              3
            </p>
            <Button
              disabled={!uploadCheckbox || !isConfirmed}
              onClick={handleExecute}
            >
              Execute
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolMigration;
