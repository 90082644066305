import {
  DirectionsRenderer,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../redux/slices/auth.slice";

const BusMap = ({ routeData, routeMapData, routeId }) => {
  const mapRef = useRef();
  const [devicePositions, setDevicePositions] = useState([]);
  const [error, setError] = useState(null);
  const [socket, setSocket] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 28.7041,
    lng: 77.1025,
  });
  const [directionResponse, setDirectionResponse] = useState(null);

  const user = useSelector(selectUser);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const createSession = async () => {
    try {
      await axios.post(
        "https://web.onelap.in/api/session",
        {
          email: user?.onelapUser?.phone,
          password: user?.onelapUser?.password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      );
    } catch (err) {
      setError("Check the credentials is properly added or not", err.message);
    }
  };

  const connectSocket = useCallback(() => {
    const socketInstance = new WebSocket("wss://web.onelap.in/api/socket");

    socketInstance.onopen = () => {
      console.log("WebSocket is open now.");
    };

    socketInstance.onerror = (error) => {
      setError("Check the credentials");
    };

    socketInstance.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data && data?.positions) {
          setDevicePositions(data?.positions);
          const firstDevice = data.positions[0];
          if (mapRef.current) {
            mapRef.current.panTo({
              lat: firstDevice.latitude,
              lng: firstDevice.longitude,
            });
          }
          setMapCenter({
            lat: firstDevice.latitude,
            lng: firstDevice.longitude,
          });
        }
      } catch (err) {
        console.error("Error processing WebSocket message", err);
      }
    };

    socketInstance.onclose = () => {
      console.log("WebSocket is closed now.");
    };

    setSocket(socketInstance);

    return socketInstance;
  }, []);

  useEffect(() => {
    const initializeTracker = async () => {
      if (routeId) {
        // Check if routeId exists
        await createSession();
        connectSocket();
      }
    };

    initializeTracker();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [connectSocket, routeId]);

  const handleMarkerClick = (lat, lng) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
    }
  };

  useEffect(() => {
    const fetchDirections = async () => {
      if (routeData?.source_location && routeData?.destination_location) {
        //eslint-disable-next-line no-undef
        const directionService = new google.maps.DirectionsService();

        try {
          const results = await directionService.route({
            //eslint-disable-next-line no-undef
            origin: new google.maps.LatLng(
              routeData?.source_location?.coordinates?.[0],
              routeData?.source_location?.coordinates?.[1]
            ),
            //eslint-disable-next-line no-undef
            destination: new google.maps.LatLng(
              routeData?.destination_location?.coordinates?.[0],
              routeData?.destination_location?.coordinates?.[1]
            ),
            // waypoints: routeMapData?.map((point) => ({
            //   //eslint-disable-next-line no-undef
            //   location: new google.maps.LatLng(
            //     point.coordinates[0],
            //     point.coordinates[1]
            //   ),
            //   stopover: true, // Indicates the waypoint is a stop
            // })),
            //eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING, // Adjust travel mode if needed
          });
          setDirectionResponse(results);
          // // eslint-disable-next-line no-undef
          // new google.maps.Marker({
          //   position: {
          //     lat: routeData?.source_location?.coordinates?.[0],
          //     lng: routeData?.source_location?.coordinates?.[1],
          //   },
          //   map: mapRef.current, // Reference to the map instance
          //   label: "Start",
          // });

          // //eslint-disable-next-line no-undef
          // new google.maps.Marker({
          //   position: {
          //     lat: routeData?.destination_location?.coordinates?.[0],
          //     lng: routeData?.destination_location?.coordinates?.[1],
          //   },
          //   map: mapRef.current,
          //   label: "End",
          // });
        } catch (error) {
          console.error("Error fetching directions:", error);
        }
      }
    };

    fetchDirections();
  }, [routeData]);

  return (
    <div>
      {error && <div style={{ color: "red", margin: "10px 0" }}>{error}</div>}

      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        libraries={["places"]}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          zoom={15}
          onLoad={(map) => (mapRef.current = map)}
        >
          {routeData?.source_location && (
            <Marker
              position={{
                lat: routeData?.source_location?.coordinates?.[0],
                lng: routeData?.source_location?.coordinates?.[1],
              }}
              label={{
                text: "Start",
                color: "white",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            />
          )}

          {routeData?.destination_location && (
            <Marker
              position={{
                lat: routeData?.destination_location?.coordinates?.[0],
                lng: routeData?.destination_location?.coordinates?.[1],
              }}
              label={{
                text: "End",
                color: "white",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            />
          )}

          {devicePositions.map((device) => (
            <Marker
              key={device.deviceId}
              position={{ lat: device.latitude, lng: device.longitude }}
              onClick={() => handleMarkerClick(device)}
              icon={{
                url: "https://maps.google.com/mapfiles/kml/shapes/bus.png",
                scaledSize: new window.google.maps.Size(20, 20),
              }}
            />
          ))}
          {devicePositions &&
            devicePositions?.length > 0 &&
            devicePositions.map((data) => {
              const position = {
                lat: data?.latitude,
                lng: data?.longitude,
              };

              return (
                <Marker
                  key={data?.deviceId}
                  position={position}
                  onClick={() => {
                    handleMarkerClick(position, position);
                    alert(`
                      Device ID: ${data?.deviceId}
                      Speed: ${data.details.speed} km/h
                      Battery: ${data.details.battery}%
                      Ignition: ${data.details.ignition ? "On" : "Off"}
                      Total Distance: ${(
                        data.details.totalDistance / 1000
                      ).toFixed(2)} km
                      Last Update: ${new Date(
                        data.details.timestamp
                      ).toLocaleString()}
                    `);
                  }}
                  icon={{
                    url: "https://maps.google.com/mapfiles/kml/shapes/bus.png",
                    scaledSize: new window.google.maps.Size(20, 20),
                  }}
                />
              );
            })}
          {directionResponse && (
            <DirectionsRenderer
              directions={directionResponse}
              options={{
                suppressMarkers: true,
              }}
            />
          )}
          {/* {directionResponse && (
            <Marker
              position={directionResponse.routes[0].legs[0].start_location.toJSON()}
              label={{
                text: "Start Point",
                color: "white",
                fontWeight: "bold",
                fontSize: "14px",
              }}
              // icon={{
              //   url: "https://maps.google.com/mapfiles/kml/paddle/grn-circle.png", // Custom icon for start
              //   scaledSize: new window.google.maps.Size(40, 40),
              // }}
            />
          )} */}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default BusMap;
