import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import axios from "axios";
import * as moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { BiSearch } from "react-icons/bi";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";
const { Option } = Select;

function tagRender(props) {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      className="flex items-center"
    >
      {label}
    </Tag>
  );
}
const GenderSelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Gender"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <Option value="male">Male</Option>
      <Option value="female">Female</Option>
      <Option value="others">Other</Option>
    </Select>
  );
};
const BloodGroupSelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Blood Group"
      getPopupContainer={(trigger) => trigger.parentNode}
      allowClear
    >
      <Option value="A+">A+</Option>
      <Option value="B+">B+</Option>
      <Option value="O+">O+</Option>
      <Option value="A-">A-</Option>
      <Option value="B-">B-</Option>
      <Option value="O-">O-</Option>
      <Option value="AB+">AB+</Option>
      <Option value="AB-">AB-</Option>
    </Select>
  );
};

function ManageStudentMain() {
  const { Search } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const history = useHistory();
  const QueryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [selectButton, setSelectButton] = useState("All");
  const [classId, setClassId] = useState(null);
  const { Title } = Typography;

  const [addstudent, setAddStudent] = useState(false);
  const [subjectListing, setSubjectListing] = useState({
    subjects: [],
    co_curricular: [],
    languages: [],
  });
  const [transportationCheckbox, setTransportationCheckbox] = useState(false);
  const [accommodationCheckbox, setAccommodationCheckbox] = useState(false);
  const [routeId, setRouteId] = useState();

  //get routes
  async function fetchRoute() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routes = useQuery(["routes"], () => fetchRoute());

  async function fetchRouteDetails(routeId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/${routeId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routesDetail = useQuery(
    ["routes", routeId],
    () => routeId && fetchRouteDetails(routeId),
    {
      enabled: !!routeId,
    }
  );

  // fetch subject
  async function fetchSubjects() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const subjects = useQuery("subjects", fetchSubjects);

  // fetch students
  async function getStudents() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/users/",
      params: {
        role: "student",
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const students = useQuery("students", getStudents);

  // fetch Class
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  useEffect(() => {
    if (classId && subjects?.isSuccess) {
      const mandatorySubjects = subjects?.data?.filter(
        (s) => s?.class?._id === classId && s?.is_mandatory
      );
      const coCurricularSubjects = subjects?.data?.filter(
        (s) => s?.class?._id === classId && s?.is_co_curricular
      );
      const languageSubjects = subjects?.data?.filter(
        (s) => s?.class?._id === classId && s?.is_language
      );

      setSubjectListing({
        subjects: mandatorySubjects?.map((s) => s?._id),
        co_curricular: coCurricularSubjects?.map((s) => s?._id),
        languages: languageSubjects?.map((s) => s?._id),
      });
    }
  }, [classId, subjects?.data]);

  const onFinish = (values) => {
    const payload = {
      role: "student",
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      username: values?.username,
      password: values?.confirm,
      class: values?.class,
      subjects: [...subjectListing.subjects],
      languages: values?.language ? values?.language : [],
      co_curricular: values?.curricular ? values?.curricular : [],
      nationality: values?.nationality,
      birth_place: values?.birth_place,
      religion: values?.religion,
      category: values?.category,
      ethnicity: values?.ethnicity,
      blood_group: values?.blood_group,
      aadhar: values?.aadhar,
      admission_number: values?.admissionNumber,
      gender: values?.gender,
      phone: values?.phone,
      admission_date: moment(values?.admissionDate).format("YYYY-MM-DD"),
      dob: moment(values?.dob).format("YYYY-MM-DD"),
      is_transportation_required: transportationCheckbox,
      is_accommodation_required: accommodationCheckbox,
      route_id: values.route_id,
      route_entry_point_id: values?.route_entry_point_id,
      primary_contact: {
        first_name: values?.primary_contact_first_name,
        last_name: values?.primary_contact_last_name,
        relation: values?.primary_contact_relation,
        contact: values?.primary_contact_phone,
        email: values?.primary_contact_email,
      },
      secondary_contact: {
        first_name: values?.secondary_contact_first_name,
        last_name: values?.secondary_contact_last_name,
        relation: values?.secondary_contact_relation,
        contact: values?.secondary_contact_phone,
        email: values?.secondary_contact_email,
      },
    };
    axios({
      method: "post",
      url: serverUrl + "/users/",
      data: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        setAddStudent(false);
        form.resetFields();
        setSubjectListing({
          subjects: [],
          co_curricular: [],
          languages: [],
        });
        message.success("Student added");
        QueryClient.invalidateQueries("students");
      })
      .catch((error) => {
        if (error?.response?.data) {
          message.error(error?.response?.data.message);
        } else {
          message.error(error?.message);
        }
      });
  };

  const selectChange = (values) => {
    setSelectButton(values);
  };

  // Table config
  const columns = [
    {
      title: "Student Name",
      key: "x",
      render: (text) => (
        <div
          key={text?._id}
          style={{ color: "#616161" }}
          className=" text-base font-semibold capitalize"
        >
          {text?.student?.first_name} {text?.student?.last_name}
        </div>
      ),
    },
    {
      title: "Class",
      key: "",
      render: (text) => (
        <div
          key={text?._id}
          style={{ color: "#616161" }}
          className=" text-base font-semibold"
        >
          {text?.student?.class?.name}
        </div>
      ),
      filters: classes?.isSuccess
        ? classes?.data?.map((c) => ({ text: c.name, value: c?._id }))
        : [],
      onFilter: (value, record) => record?.student?.class?._id === value,
    },
    {
      title: "Roll Number",
      key: "rollNumber",
      dataIndex: ["student", "roll_number"],
      sorter: (a, b) => b.student.roll_number - a.student.roll_number,
      render: (text, record) => (
        <div
          key={record._id}
          style={{ color: "#616161" }}
          className="text-base font-semibold capitalize"
        >
          {text}
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text) => (
        <div
          key={text?._id}
          className=" text-base font-semibold text-blue-500 cursor-pointer"
          onClick={() =>
            history.push(`/dashboard/admin/menu/student/profile/${text?._id}`)
          }
        >
          View Details
        </div>
      ),
    },
  ];

  const filteredStudents = useMemo(() => {
    let filtered = students.data;

    if (search) {
      filtered = filtered.filter(
        (f) =>
          f.student?.first_name.toLowerCase().includes(search) ||
          f.student?.last_name.toLowerCase().includes(search) ||
          (f.student.first_name + " " + f.student.last_name)
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    return filtered;
  }, [students.data, search]);

  const studentExportData = useMemo(() => {
    const ExportData = [];
    students?.data &&
      students.data?.forEach((student) => {
        ExportData?.push({
          Name:
            student?.student?.first_name + " " + student?.student?.last_name,
          Batch: student?.student?.batch,
          Class: student?.student?.class?.name,
          Subjects: student?.student?.subjects?.map((s) => s.name).join(", "),
          Attendance: student?.attendance
            ? (student?.attendance?.filter((a) => a.attended === true)?.length /
                student?.attendance?.length) *
              100
            : "",
          Email: student?.email,
          Username: student?.username,
          " Academic Year": `${moment(
            student?.student?.class?.academic_start_date
          ).format("yyyy")}-${moment(
            student?.student?.class?.academic_end_date
          ).format("yyyy")}`,
        });
      });
    return ExportData;
  }, [students.data]);

  const validateAlphabetsOnly = (_, value) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(value)) {
      return Promise.reject("Please enter alphabets only");
    }
    return Promise.resolve();
  };
  useEffect(() => {
    form.resetFields(["language", "curricular"]);
  }, [classId, form]);

  return (
    <>
      <div className="p-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="flex items-center justify-between">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Students
            </div>
            <div className=" flex space-x-5">
              <Button onClick={() => setAddStudent(true)} type="primary">
                Add New Student
              </Button>
              <CSVLink filename={`students.csv`} data={studentExportData}>
                <Button>Export Students</Button>
              </CSVLink>
              <div compact className="flex flex-row items-center">
                <Search
                  placeholder="input search text"
                  enterButton="Search"
                  loading={false}
                  style={{ backgroundColor: "#E3E3E3" }}
                  prefix={<BiSearch className="text-gray-600 " />}
                  onSearch={(e) => setSearch(e)}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="py-5">
            <Table
              columns={columns}
              dataSource={filteredStudents?.sort((a, b) =>
                moment(a?.student?.createdAt).isBefore(b?.student?.createdAt)
                  ? 1
                  : -1
              )}
              pagination={true}
            />
          </div>
        </div>
      </div>
      {/* ----------------------------------modal------------------------------------ */}

      <Modal
        width={800}
        visible={addstudent}
        onOk={() => setAddStudent(false)}
        onCancel={() => {
          setAddStudent(false);
          form.resetFields();
          // setSubjectListing({
          //   subjects: [],
          //   co_curricular: [],
          //   languages: [],
          // });
          setClassId("");
        }}
        footer={null}
      >
        <div>
          <div className="py-2">
            <div className="text-2xl font-bold text-black pb-4 capitalize">
              Add Student
            </div>
          </div>
          {/* ---------------------------form---------------- */}

          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
              </div>

              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="email"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="admission_date"
                  label="Admission Date"
                  rules={[
                    {
                      required: true,
                      message: "Admission date  is required",
                    },
                  ]}
                  className="w-30"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Please select"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="admissionNumber"
                  label="Admission Number"
                  className="w-30"
                  getValueFromEvent={(e) => e.target.value}
                  rules={[
                    {
                      required: true,
                      message: "Admission number  is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="aadhar"
                  label="Aadhar"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please enter your aadhar id",
                    },
                    {
                      pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                      message: "Please enter a valid 12-digit Number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 12)}
                >
                  <Input maxLength={12} type="number" min={0} />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="blood_group"
                  label="Blood Group"
                  className="w-30"
                >
                  <BloodGroupSelect />
                </Form.Item>
                <Form.Item
                  label="Class"
                  name="class"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please select a class",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select Class"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    className="w-full bg-gray-300"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    onChange={(e) => {
                      setClassId(e);
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {classes.isSuccess &&
                      classes?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
              {classId && (
                <>
                  <div className="pb-2"> Mandatory</div>
                  <div className="flex flex-wrap gap-3 pb-4">
                    {subjects.isSuccess &&
                      subjects?.data
                        ?.filter(
                          (s) =>
                            s.class?._id === classId && s.is_mandatory === true
                        )
                        ?.map((data) => (
                          <div
                            key={data._id}
                            className="bg-gray-200 px-4 py-2 rounded-md text-gray-700 iskool-friends-card capitalize"
                          >
                            {data.name}
                          </div>
                        ))}
                  </div>
                  <Form.Item name="language" label="Languages">
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={tagRender}
                      placeholder="Select language"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      className="w-full"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {subjects.isSuccess &&
                        subjects?.data
                          ?.filter(
                            (s) =>
                              s?.class?._id === classId &&
                              s?.is_language === true
                          )
                          ?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data?.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="curricular" label="Co-Curricular">
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={tagRender}
                      placeholder="Select co-Curricular"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      className="w-full"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {subjects.isSuccess &&
                        subjects?.data
                          ?.filter(
                            (s) =>
                              s?.class?._id === classId &&
                              s?.is_co_curricular === true
                          )
                          ?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data?.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </>
              )}
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="dob"
                  label="Date Of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Date of Birth  is required",
                    },
                  ]}
                  className="w-full"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Please select your date of birth"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender is required",
                    },
                  ]}
                  className="w-30"
                >
                  <GenderSelect />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="phone"
                  label="Phone"
                  className="w-30"
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                      message: "Please enter a valid 10-digit phone number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                >
                  <Input type="number" maxLength={10} min={0} />
                </Form.Item>
                <Form.Item
                  name="birth_place"
                  label="Birth Place"
                  className="w-30"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="category"
                  label="Category"
                  className="w-30"
                  rules={[
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="religion"
                  label="Religion"
                  className="w-30"
                  rules={[
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="nationality"
                  label="Nationality"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Nationality is required",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="ethnicity" label="Ethnicity" className="w-30">
                  <Input />
                </Form.Item>
              </div>
              <Title level={2}>Primary Contact</Title>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="primary_contact_first_name"
                  label="First Name"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "First name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="primary_contact_last_name"
                  label="Last Name"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Last name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="primary_contact_phone"
                  label="Phone"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Phone is required",
                    },
                    {
                      pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                      message: "Please enter a valid 10-digit phone number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                >
                  <Input type="number" maxLength={10} min={0} />
                </Form.Item>
                <Form.Item
                  name="primary_contact_email"
                  label="Email"
                  className="w-30"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="primary_contact_relation"
                  label="Relation"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Relation is required",
                    },
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <Title level={2}>Secondary Contact</Title>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="secondary_contact_first_name"
                  label="First Name"
                  className="w-30"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="secondary_contact_last_name"
                  label="Last Name"
                  className="w-30"
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="secondary_contact_phone"
                  label="Phone"
                  className="w-30"
                  rules={[
                    {
                      pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                      message: "Please enter a valid 10-digit phone number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                >
                  <Input type="number" maxLength={10} min={0} />
                </Form.Item>
                <Form.Item
                  name="secondary_contact_email"
                  label="Email"
                  className="w-30"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="secondary_contact_relation"
                  label="Relation"
                  className="w-30"
                  rules={[
                    {
                      validator: validateAlphabetsOnly,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <Title level={2}>Transportation</Title>
              <Form.Item
                name="is_transportation_required"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) => {
                    setTransportationCheckbox(e.target.checked);
                    form.resetFields(["route_id", "route_entry_point_id"]);
                  }}
                  checked={transportationCheckbox}
                >
                  Transportation require?
                </Checkbox>
              </Form.Item>
              <div>
                {transportationCheckbox && (
                  <div className="admission-form">
                    <Form.Item label="Route" name="route_id" className="w-full">
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select Route"
                        className=" bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(value) => {
                          setRouteId(value);
                          form.resetFields(["route_entry_point_id"]);
                        }}
                      >
                        {routes?.isSuccess &&
                          routes?.data?.result?.data?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data?.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Route Entry Point"
                      name="route_entry_point_id"
                      className="w-full"
                    >
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select Route Entry Point"
                        className="w-full bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {routesDetail?.isSuccess &&
                          routesDetail?.data?.result?.data?.route_map?.map(
                            (data) => {
                              return (
                                <Option key={data._id} value={data._id}>
                                  {data.name}
                                </Option>
                              );
                            }
                          )}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </div>
              <Title level={2}>Accommodation</Title>
              <Form.Item
                name="is_accommodation_required"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) => {
                    setAccommodationCheckbox(e.target.checked);
                  }}
                  checked={accommodationCheckbox}
                >
                  Accommodation require?
                </Checkbox>
              </Form.Item>
            </div>
            {/* ----------------------------------------------- */}
            <div className="flex justify-end items-center pt-6">
              <div className="inline-flex items-center space-x-2">
                <Button
                  onClick={() => {
                    setAddStudent(false);
                    form.resetFields();
                  }}
                  size="large"
                  type="dark"
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="text-white"
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default ManageStudentMain;
