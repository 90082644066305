import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Tag,
  Typography,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { BiSearch } from "react-icons/bi";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";
import InstructorCard from "../cards/InstructorCard";
const { Option } = Select;
const { Title } = Typography;

function tagRender(props) {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      // color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}
const BloodGroupSelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Blood Group"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <Option value="A+">A+</Option>
      <Option value="B+">B+</Option>
      <Option value="O+">O+</Option>
      <Option value="A-">A-</Option>
      <Option value="B-">B-</Option>
      <Option value="O-">O-</Option>
      <Option value="AB+">AB+</Option>
      <Option value="AB-">AB-</Option>
    </Select>
  );
};

function InstructorPage() {
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Search } = Input;
  const queryClient = useQueryClient();

  const [addinstructor, setAddInstructor] = useState(false);
  const [selectDepartment, setSelectDepartment] = useState();
  const [search, setSearch] = useState(null);

  const [transportationCheckbox, setTransportationCheckbox] = useState(false);
  const [routeId, setRouteId] = useState();

  //get routes
  async function fetchRoute() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routes = useQuery(["routes"], () => fetchRoute());

  async function fetchRouteDetails(routeId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/${routeId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routesDetail = useQuery(
    ["routes", routeId],
    () => routeId && fetchRouteDetails(routeId),
    {
      enabled: !!routeId,
    }
  );
  //fetch instructor
  async function getInstructor(id) {
    let url = `${serverUrl}/users/instructor`;
    if (id) {
      url += `?department=${id}`;
    }

    const res = await axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const instructors = useQuery(["users/instructors", selectDepartment], () =>
    getInstructor(selectDepartment)
  );
  //fetch subject
  const fetchSubjects = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const subjects = useQuery("subjects", fetchSubjects, {
    useErrorBoundary: true,
  });

  // fetch departments
  async function fetchDepartments() {
    const response = await axios.get(serverUrl + "/department", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return response.data;
  }
  const departments = useQuery("departments", fetchDepartments);

  const handleSelectDepartment = (value) => {
    setSelectDepartment(value);
  };

  const filteredInstructors = useMemo(() => {
    let filtered = instructors?.data?.data;
    if (search) {
      filtered = filtered?.filter(
        (f) =>
          f?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
          f?.last_name?.toLowerCase().includes(search.toLowerCase()) ||
          (f.first_name + " " + f.last_name)
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }
    return filtered;
  }, [instructors?.data, search]);

  const onFinish = (values) => {
    values["role"] = "instructor";
    axios({
      method: "post",
      url: serverUrl + "/users/",
      data: values,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((res) => {
        setAddInstructor(false);
        form.resetFields();
        message.success("Instructor added");
        queryClient.invalidateQueries(["users"]);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        }
      });
  };
  const instructorExportData = useMemo(() => {
    const ExportData = [];
    instructors?.data?.data &&
      instructors.data?.data?.forEach((student) => {
        ExportData?.push({
          Name: student?.first_name + " " + student?.last_name,
          Email: student?.email,
          Subjects: student?.subjects?.map((s) => s.name).join(", "),
          Class: student?.subjects?.map((s) => s.class?.name).join(", "),
          Department: student?.department?.name,
          Username: student?.username,
        });
      });
    return ExportData;
  }, [instructors?.data?.data]);
  return (
    <div>
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="flex items-center justify-between">
            <div className="text-2xl text-orange-500 font-semibold">
              Manage Instructors
            </div>
            <div className=" flex space-x-5">
              <Button
                onClick={() => setAddInstructor(true)}
                type="primary"
                className="h-10"
              >
                Add New Instructor
              </Button>
              <CSVLink filename={`instructors.csv`} data={instructorExportData}>
                <Button size="large">Export Instructors</Button>
              </CSVLink>
              <Search
                placeholder="input search text"
                enterButton="Search"
                size="large"
                loading={false}
                style={{ backgroundColor: "#E3E3E3" }}
                prefix={<BiSearch className="text-gray-600 " />}
                onSearch={(e) => setSearch(e)}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-10px pt-8">
            <h6 className=" font-semibold text-base">Department: &nbsp;</h6>
            <Select
              showArrow
              placeholder="Select department"
              className="w-1/2 bg-gray-300"
              style={{
                border: "6px",
                backgroundColor: "#EBEBEB",
                width: "23%",
              }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={handleSelectDepartment}
              allowClear
            >
              {departments?.isSuccess &&
                departments?.data?.map((data) => {
                  return (
                    <Option key={data._id} value={data._id}>
                      {data?.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className="py-8 grid lg:grid-cols-2 xl:grid-cols-3 gap-6">
            {filteredInstructors && filteredInstructors.length > 0 ? (
              filteredInstructors?.map((data) => (
                <InstructorCard key={data._id} data={data} />
              ))
            ) : (
              <div className="text-base">No data available</div>
            )}
          </div>
          {/* -------------------------------------------------------------------- */}
          <Modal
            width={800}
            visible={addinstructor}
            onOk={() => setAddInstructor(false)}
            onCancel={() => {
              setAddInstructor(false);
              form.resetFields();
            }}
            footer={null}
          >
            <div>
              <div className="py-2">
                <div className="text-2xl font-bold text-black pb-4 capitalize">
                  Add Instructor
                </div>
              </div>
              {/* ---------------------------form---------------- */}
              <Form
                layout="vertical"
                name="basic"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                        {
                          message:
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                          validator: (_, value) => {
                            if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input type="text" />
                    </Form.Item>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                        },
                        {
                          message:
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                          validator: (_, value) => {
                            if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input type="text" />
                    </Form.Item>
                  </div>

                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input type="text" />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      label="Email Address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                        {
                          min: 6,
                          message:
                            "Password must be at least 6 characters long!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      name="confirm"
                      label="Confirm Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      name="phone"
                      label="Phone"
                      rules={[
                        {
                          pattern: /^[0-9]{10}$/, // Adjust the regex pattern as needed
                          message:
                            "Please enter a valid 10-digit phone number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                    >
                      <Input type="number" maxLength={10} min={0} />
                    </Form.Item>
                    <Form.Item label="Department" name="department">
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select Department"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {departments.isSuccess &&
                          departments?.data?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      name="aadhar"
                      label="Aadhar"
                      className="w-30"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter your aadhar id",
                        },
                        {
                          pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                          message: "Please enter a valid 12-digit Number!",
                        },
                      ]}
                      getValueFromEvent={(e) => e.target.value.slice(0, 12)}
                    >
                      <Input maxLength={12} type="number" min={0} />
                    </Form.Item>
                    <Form.Item
                      name="dob"
                      label="Date Of Birth"
                      rules={[
                        {
                          required: true,
                          message: "Date of Birth  is required",
                        },
                      ]}
                      className="w-full"
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        placeholder="Please select your date of birth"
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        className="w-full"
                      />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      name="employment_started_at"
                      label="Employment Start Date"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Employment Start Date  is required",
                      //   },
                      // ]}
                      className="w-full"
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        placeholder="Please select"
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        className="w-full"
                      />
                    </Form.Item>
                    <Form.Item
                      name="employment_ended_at"
                      label="Employment End Date"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Employment End Date  is required",
                      //   },
                      // ]}
                      className="w-full"
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        placeholder="Please select"
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        className="w-full"
                      />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    <Form.Item
                      name="address"
                      label="Address"
                      className="w-30"
                      getValueFromEvent={(e) => e.target.value}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="blood_group"
                      label="Blood Group"
                      className="w-30"
                    >
                      <BloodGroupSelect />
                    </Form.Item>
                  </div>
                  <Form.Item label="Title" name="title">
                    <Input />
                  </Form.Item>

                  <div className="pb-2">Subjects Assigned</div>
                  <Form.Item name="subjects">
                    <Select
                      mode="multiple"
                      showArrow
                      tagRender={tagRender}
                      placeholder="Select Subjects"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      className="w-3/4"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {subjects.isSuccess &&
                        subjects?.data
                          ?.filter((s) => !s.teacher)
                          ?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data?.class?.name + " " + data.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                  <Title level={2}>Transportation</Title>
                  <Form.Item
                    name="is_transportation_required"
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={(e) => {
                        setTransportationCheckbox(e.target.checked);
                        form.resetFields(["route_id", "route_entry_point_id"]);
                      }}
                      checked={transportationCheckbox}
                    >
                      Transportation require?
                    </Checkbox>
                  </Form.Item>
                  {transportationCheckbox && (
                    <div className="admission-form">
                      <Form.Item
                        label="Route"
                        name="route_id"
                        className="w-full"
                      >
                        <Select
                          showSearch
                          showArrow
                          placeholder="Select Route"
                          className=" bg-gray-300"
                          style={{
                            border: "6px",
                            backgroundColor: "#EBEBEB",
                          }}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          onChange={(value) => {
                            setRouteId(value);
                            form.resetFields(["route_entry_point_id"]);
                          }}
                        >
                          {routes?.isSuccess &&
                            routes?.data?.result?.data?.map((data) => {
                              return (
                                <Option key={data._id} value={data._id}>
                                  {data?.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Route Entry Point"
                        name="route_entry_point_id"
                        className="w-full"
                      >
                        <Select
                          showSearch
                          showArrow
                          placeholder="Select Route Entry Point"
                          className="w-full bg-gray-300"
                          style={{
                            border: "6px",
                            backgroundColor: "#EBEBEB",
                          }}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {routesDetail?.isSuccess &&
                            routesDetail?.data?.result?.data?.route_map?.map(
                              (data) => {
                                return (
                                  <Option key={data._id} value={data._id}>
                                    {data.name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {/* ----------------------------------------------- */}
                <div className="flex justify-end items-center pt-6">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      onClick={() => {
                        setAddInstructor(false);
                        form.resetFields();
                      }}
                      size="large"
                      type="dark"
                    >
                      Cancel
                    </Button>

                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default InstructorPage;
