import { Button, DatePicker, Input, Table, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { serverUrl } from "../../../../../nestserver";

const StudentFeDetails = ({
  classId,
  feeResponse,
  setFeeResponse,
  isViewStudentFee,
  studentId,
  fetchStudentFee,
  isParent,
  
}) => {
  const [editedValues, setEditedValues] = useState({});
  const [editedStudentFeeValues, setEditedStudentFeeValues] = useState({});
  const [editingStudentFeeRow, setEditingStudentFeeRow] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [editableStudentFee, setEditableStudentFee] = useState([]);
  const [studentFee, setStudentFee] = useState();

  const feeColumn = [
    {
      title: "Name",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.fee_template?.type}
        </div>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: [],
      key: "",
      render: (record) => {
        const isEditing = editingKey === record._id;

        return isEditing ? (
          <Input
            type="number"
            defaultValue={record?.total}
            onChange={(e) => {
              const newStudentFee = editableStudentFee.map((item) =>
                item._id === record._id
                  ? { ...item, total: e.target.value }
                  : item
              );
              setEditableStudentFee(newStudentFee);
            }}
          />
        ) : (
          <div style={{ color: "#616161" }} className="text-base">
            {record?.total}
          </div>
        );
      },
    },
    {
      title: "Paid Amount",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div style={{ color: "#616161" }} className=" text-base">
          {record?.paid}
        </div>
      ),
    },

    {
      title: "Remaining Amount",
      dataIndex: [],
      key: "",
      render: (record) => {
        const isEditing = editingKey === record._id;

        return isEditing ? (
          <Input
            type="number"
            defaultValue={record?.remaining}
            onChange={(e) => {
              const newStudentFee = editableStudentFee.map((item) =>
                item._id === record._id
                  ? { ...item, remaining: e.target.value }
                  : item
              );
              setEditableStudentFee(newStudentFee);
            }}
          />
        ) : (
          <div style={{ color: "#616161" }} className="text-base">
            {record?.remaining}
          </div>
        );
      },
    },
    {
      title: "Due Date",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => {
        const isEditing = editingKey === record._id;

        return isEditing ? (
          <DatePicker
            defaultValue={record?.due_date ? moment(record.due_date) : null}
            format="DD/MM/YYYY"
            onChange={(date) => {
              const newStudentFee = editableStudentFee.map((item) =>
                item._id === record._id
                  ? { ...item, due_date: date ? date.toISOString() : null }
                  : item
              );
              setEditableStudentFee(newStudentFee);
            }}
          />
        ) : (
          <div
            key={record?._id}
            style={{ color: "#111111" }}
            className="text-center capitalize"
          >
            {moment(record?.due_date).format("Do MMM YYYY")}
          </div>
        );
      },
    },
    {
      title: "Last Paid",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className="text-center capitalize"
        >
          {record?.last_paid
            ? moment(record?.last_paid).format("Do MMM YYYY")
            : ""}
        </div>
      ),
    },
  ];
  if (!isParent) {
    feeColumn.push({
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => {
        const isEditing = editingKey === record._id;

        return isEditing ? (
          <>
            <Button
              type="primary"
              onClick={() => saveEdit(record)}
              style={{ marginRight: 8 }}
            >
              Save
            </Button>
            <Button onClick={cancelEditing}>Cancel</Button>
          </>
        ) : (
          <Button type="link" onClick={() => startEditing(record)}>
            Edit
          </Button>
        );
      },
    });
  }

  const fetchStudentFees = async () => {
    // Validate inputs
    if (!classId || !studentId) {
      message.error("Class ID and Student ID are required");
      return;
    }

    try {
      const response = await axios({
        method: "get",
        url: `${serverUrl}/admin/fee-template/class/${classId}/student/${studentId}`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      if (response.data?.result) {
        setStudentFee(response.data.result);
      } else {
        message.info("No fee data found");
        setStudentFee([]);
      }
    } catch (err) {
      message.error("Failed to fetch student fees");
    } finally {
    }
  };

  useEffect(() => {
    if (classId && studentId) {
      fetchStudentFees();
    }
  }, [classId, studentId]);

  const startEditing = (record) => {
    setEditingKey(record._id);
    setEditableStudentFee([...studentFee]);
  };
  const cancelEditing = () => {
    setEditingKey("");
  };
  const saveEdit = async (record) => {
    try {
      const updatePayload = {
        remaining: editableStudentFee.find((item) => item._id === record._id)
          ?.remaining,
        due_date: editableStudentFee.find((item) => item._id === record._id)
          ?.due_date,
        total: editableStudentFee.find((item) => item._id === record._id)
          ?.total,
      };

      const response = await axios.patch(
        `${serverUrl}/admin/student-fee/${record._id}`,
        updatePayload,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );

      // Update local state
      const newStudentFee = studentFee.map((item) =>
        item._id === record._id ? { ...item, ...updatePayload } : item
      );

      setStudentFee(newStudentFee);
      setEditingKey("");
      message.success("Fee details updated successfully");
    } catch (error) {
      message.error("Failed to update fee details");
    }
  };

  const handleStudentFeeCancel = (index) => {
    setEditingStudentFeeRow({});
    setEditedStudentFeeValues((prev) => {
      const newValues = { ...prev };
      delete newValues[index];
      return newValues;
    });
  };

  const handleStudentFeeEdit = (record, index, dataIndex) => {
    setEditingStudentFeeRow({
      dataIndex: dataIndex,
      index: index,
    });

    setEditedStudentFeeValues({
      ...editedStudentFeeValues,
      [index]: {
        ...record.payable_components[0],
      },
    });
  };

  function disabledDate(current) {
    return current && current < moment().startOf("day");
  }

  // handle update for name and due_date

  const handleStudentFeeUpdate = async (index, dataIndex) => {
    try {
      const updatedData = [
        ...(feeResponse?.[dataIndex]?.installment_list || []),
      ];
      const updatedItem = {
        payable_components: editedStudentFeeValues[index],
        _id: updatedData[index]._id,
      };

      const response = await axios.patch(
        `${serverUrl}/admin/student-fee/${feeResponse?.[dataIndex]?._id}/installments/`,
        updatedItem,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      message.success(response?.data?.result?.message);
      const updatedStudentFee = await fetchStudentFee();
      updatedData[index].payable_components[0] =
        updatedStudentFee.result.installment_list[index].payable_components[0];
      setFeeResponse({
        ...feeResponse[dataIndex],
        installment_list: updatedData,
      });

      // Reset editing state
      setEditingStudentFeeRow({});
      setEditedStudentFeeValues({});
    } catch (error) {
      setEditingStudentFeeRow({});
      setEditedStudentFeeValues({});
    }
  };
  // Table Settings

  const getDynamicColumns = (data, dataIndex) => {
    const columns = [
      {
        title: "Name",
        key: "name",
        align: "center",
        render: (record, _, index) => {
          if (isEditMode) {
            return (
              <Input
                value={editedValues[index]?.name}
                onChange={(e) =>
                  setEditedValues({
                    ...editedValues,
                    [index]: {
                      ...editedValues[index],
                      name: e.target.value,
                    },
                  })
                }
              />
            );
          } else {
            return <span>{record?.name}</span>;
          }
        },
      },
      {
        title: "Fee",
        dataIndex: "",
        key: "tution",
        align: "center",
        render: (record, _, index) => {
          if (
            editingStudentFeeRow?.index === index &&
            editingStudentFeeRow?.dataIndex === dataIndex
          ) {
            return (
              <Input
                value={editedStudentFeeValues[index]?.tution || ""}
                onChange={(e) =>
                  setEditedStudentFeeValues({
                    ...editedStudentFeeValues,
                    [index]: {
                      ...editedStudentFeeValues[index],
                      tution: e.target.value,
                    },
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
                type="number"
              />
            );
          } else if (isEditMode) {
            return (
              <Input
                type="number"
                value={editedValues[index]?.tution || ""}
                onChange={(e) =>
                  setEditedValues({
                    ...editedValues,
                    [index]: {
                      ...editedValues[index],
                      tution: e.target.value,
                    },
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
              />
            );
          } else {
            return <span>{record?.payable_components[0]?.tution}</span>;
          }
        },
      },
      {
        title: "Total Amount",
        dataIndex: [],
        key: "",
        align: "center",
        render: (record) => (
          <div style={{ color: "#616161" }} className=" text-base text-center">
            {record?.payable_components[0]?.total_amount}
          </div>
        ),
      },
      {
        title: "Due Date",
        key: "due_date",
        align: "center",
        render: (record, _, index) => {
          if (isEditMode) {
            return (
              <DatePicker
                format={"DD/MM/YYYY"}
                value={
                  editedValues[index]?.dueDate
                    ? moment(editedValues[index].dueDate)
                    : null
                }
                disabledDate={disabledDate}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(date) =>
                  setEditedValues({
                    ...editedValues,
                    [index]: {
                      ...editedValues[index],
                      dueDate: date,
                    },
                  })
                }
              />
            );
          } else {
            return (
              <span>{moment(record?.due_date).format("Do MMM YYYY")}</span>
            );
          }
        },
      },
    ];

    const excludedProperties = ["tution", "total_amount"];

    const payable_components = data?.map((item) => item?.payable_components[0]);
    const filteredData = payable_components?.map((obj) =>
      Object.keys(obj)
        .filter((key) => !excludedProperties?.includes(key))
        .reduce((acc, key) => {
          acc[key] = obj[key];
          return acc;
        }, {})
    );
    if (filteredData && filteredData.length > 0 && filteredData[0]) {
      const firstItem = filteredData[0];
      Object.entries(firstItem).forEach(([key, value]) => {
        const newColumn = {
          title: key,
          key: key,
          align: "center",
          render: (record, _, index) => {
            const fieldValue = record?.payable_components[0]?.[key];
            const isEditing =
              editingStudentFeeRow?.index === index &&
              editingStudentFeeRow?.dataIndex === dataIndex;
            const initialValue =
              editedStudentFeeValues[index]?.[key] || fieldValue;
            return isEditing ? (
              <Input
                value={editedStudentFeeValues[index]?.[key]}
                onChange={(e) =>
                  setEditedStudentFeeValues({
                    ...editedStudentFeeValues,
                    [index]: {
                      ...editedStudentFeeValues[index],
                      [key]: e.target.value,
                    },
                  })
                }
              />
            ) : (
              <div
                key={record?._id}
                style={{ color: "#111111" }}
                className="text-base text-center capitalize"
                onClick={() => handleStudentFeeEdit(record, index)}
              >
                {initialValue}
              </div>
            );
          },
        };
        columns.splice(2, 0, newColumn);
      });
    }
    if (isViewStudentFee) {
      columns.push({
        title: "Action",
        key: "action",
        align: "center",
        render: (record, _, index) => {
          if (
            editingStudentFeeRow?.index === index &&
            editingStudentFeeRow?.dataIndex === dataIndex
          ) {
            return (
              <>
                <Button
                  type="primary"
                  onClick={() => handleStudentFeeUpdate(index, dataIndex)}
                  style={{ marginBottom: "10px" }}
                >
                  Update
                </Button>
                <Button
                  onClick={() => handleStudentFeeCancel(index)}
                  style={{ marginLeft: "8px" }}
                >
                  Cancel
                </Button>
              </>
            );
          } else {
            return (
              <Button
                type="link"
                onClick={() => handleStudentFeeEdit(record, index, dataIndex)}
              >
                Edit
              </Button>
            );
          }
        },
      });
    }

    return columns;
  };

  return (
    <>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md w-full "
        >
          <div className="p-4">
            <h3 className="text-base font-semibold text-center pb-4 capitalize">
              {"Fee Summary"}
            </h3>
            <Table
              rowKey="_id"
              columns={feeColumn}
              dataSource={studentFee && studentFee.length > 0 ? studentFee : []}
              pagination={false}
              loading={studentFee?.isLoading}
              summary={(pageData) => {
                if (!pageData || pageData.length === 0) return null;

                const totalAmount = pageData.reduce(
                  (sum, record) => sum + (Number(record?.total) || 0),
                  0
                );
                const paidAmount = pageData.reduce(
                  (sum, record) => sum + (Number(record?.paid) || 0),
                  0
                );
                const remainingAmount = pageData.reduce(
                  (sum, record) => sum + (Number(record?.remaining) || 0),
                  0
                );

                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center">
                      <strong>Total</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="">
                      <strong>{totalAmount.toLocaleString()}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="">
                      <strong>{paidAmount.toLocaleString()}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="">
                      <strong>{remainingAmount.toLocaleString()}</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={4}
                      colSpan={feeColumn.length - 4}
                    ></Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }}
            />
          </div>
          <div className="py-5 table-overflow">
            {feeResponse &&
              feeResponse.length > 0 &&
              feeResponse?.map((fee, index) => (
                <>
                  <div style={{ marginBottom: "70px" }}>
                    <h3 className="text-base font-semibold text-center pb-4 capitalize">
                      {fee?.fee_template?.type}
                    </h3>
                    <Table
                      key={index}
                      columns={getDynamicColumns(
                        fee?.installment_list || [],
                        index
                      )}
                      dataSource={
                        fee?.installment_list ? fee.installment_list : []
                      }
                      pagination={false}
                      loading={fee?.installment_list?.isLoading}
                      summary={(pageData) => {
                        const excludedProperties = [""];
                        const payable_components = pageData?.map(
                          (item) => item?.payable_components[0]
                        );
                        const dynamicColumns = Object.keys(
                          payable_components[0] || {}
                        ).filter((key) => !excludedProperties?.includes(key));

                        const columnTotals = dynamicColumns?.reduce(
                          (acc, columnKey) => {
                            const columnTotal = payable_components?.reduce(
                              (sum, component) => {
                                return (
                                  sum + (Number(component[columnKey]) || 0)
                                );
                              },
                              0
                            );

                            acc[columnKey] = columnTotal;
                            return acc;
                          },
                          {}
                        );

                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={0}
                              className="text-center"
                            >
                              <strong>Total</strong>
                            </Table.Summary.Cell>
                            {dynamicColumns?.map((columnKey, idx) => (
                              <Table.Summary.Cell
                                key={columnKey}
                                index={idx + 1}
                                className="text-center"
                              >
                                <strong>
                                  {Number(
                                    columnTotals[columnKey] || 0
                                  ).toLocaleString()}
                                </strong>
                              </Table.Summary.Cell>
                            ))}
                            <Table.Summary.Cell
                              index={dynamicColumns.length + 1}
                              className="text-center"
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>
                        );
                      }}
                    />
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentFeDetails;
