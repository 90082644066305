import { Badge, Modal, Popover, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiLogOutCircle } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { ImNotification } from "react-icons/im";
import { IoChevronDown } from "react-icons/io5";
import { RiCloseLine } from "react-icons/ri";
import { VscBell } from "react-icons/vsc";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { university } from "../../globalVariables";
import { cloudFrontUrl, serverUrl } from "../../nestserver";
import { loginUser, selectUser } from "../../redux/slices/auth.slice";
import logo from "../../resources/feedlogo.png";
import {
  useAnnouncementNotifications,
  useNotifications,
  useUpdateManyNotification,
  useUpdateNotification,
  useUpdateNotificationCount,
} from "../../services/notification.service";

export default function Header() {
  const user = useSelector(selectUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const notifications = useNotifications();
  const announcements = useAnnouncementNotifications();
  const updateNotificationMutation = useUpdateNotification();
  const updateManyNotificationMutation = useUpdateManyNotification();
  const updateNotificationCountMutation = useUpdateNotificationCount();

  const [showAllAnnouncements, setShowAllAnnouncements] = useState(false);
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const [hideShowNotification, setHideShowNotification] = useState(true);
  const [hideShowAnnouncement, setHideShowAnnouncement] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [announcementCount, setAnnouncementCount] = useState(0);

  const notifiactionShow = () => {
    setShowAllNotifications(true);
    setHideShowNotification(false);
  };
  const announcementShow = () => {
    setShowAllAnnouncements(true);
    setHideShowAnnouncement(false);
  };
  const cancelNotification = () => {
    setShowAllNotifications(false);
    setHideShowNotification(true);
  };
  const cancelAnnouncement = () => {
    setShowAllAnnouncements(false);
    setHideShowAnnouncement(true);
  };
  const getNotificationCount = async () => {
    const res = await axios.get(serverUrl + "/notification/count/mine", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    setNotificationCount(res.data);
  };

  const getAnnouncementCount = async () => {
    const res = await axios.get(
      serverUrl + "/notification/mine/count/announcements",
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );
    setAnnouncementCount(res.data);
    return res.data;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAnnouncementCount();
      getNotificationCount();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);
  function dismissNotification(id) {
    updateNotificationMutation.mutate([id, { read: true }], {
      onSuccess: () => {
        message.success("Notification dismissed");
        queryClient.invalidateQueries("notifications");
        queryClient.invalidateQueries("announcement_notifications");
      },
    });
  }

  function dismissAllNotifications(type) {
    updateManyNotificationMutation.mutate(
      {
        notifications:
          type === "notifications"
            ? notifications?.data?.map((n) => n._id)
            : announcements?.data?.map((n) => n._id),
      },
      {
        onSuccess: () => {
          message.success("All notifications dismissed");
          queryClient.invalidateQueries("notifications");
          queryClient.invalidateQueries("announcement_notifications");
        },
      }
    );
  }
  function updateAllNotifications(type) {
    updateNotificationCountMutation.mutate(
      {
        notifications:
          type === "notifications"
            ? notifications?.data?.map((n) => n._id)
            : announcements?.data?.map((n) => n._id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("notifications");
          queryClient.invalidateQueries("announcement_notifications");
        },
      }
    );

    if (type === "notifications") {
      setNotificationCount(0);
    }
    if (type === "announcement") {
      setAnnouncementCount(0);
    }
  }

  function logout() {
    window.localStorage.removeItem("jwt-token");
    dispatch(loginUser(undefined));
    message.loading("logging off..", 1).then(() => {
      history.push("/auth/login");
      // history.replace("/auth/login");
      message.success("You have been logged off");
    });
  }

  const dropdown = (
    <div className="divide-y w-28 ">
      <div
        className="flex items-center space-x-2 py-1 cursor-pointer"
        onClick={() => history.push(`/dashboard`)}
      >
        <FaRegUserCircle />
        <div>Profile</div>
      </div>
      <div
        className="flex items-center space-x-2 py-1 cursor-pointer text-red-400"
        onClick={logout}
      >
        <BiLogOutCircle />
        <div>Logout</div>
      </div>
    </div>
  );

  const notificationList = (
    <div className="divide-y w-80 ">
      {notifications?.data?.length ? (
        <>
          {notifications?.data?.map((notification) => (
            <div
              key={notification._id}
              className="flex items-center space-x-2 p-1 cursor-pointer hover hover:text-primary-500"
            >
              <ImNotification size={20} className="flex-none" />
              <div>
                <div>{notification?.title}</div>
                <div className="text-xs italic font-light">
                  {moment(notification?.createdAt).format("Do MMM YYYY")}
                </div>
                <div
                  className="text-xs line-clamp-1"
                  style={{ wordBreak: "break-word" }}
                >
                  {notification?.description}
                </div>
              </div>

              <RiCloseLine
                size={20}
                onClick={() => dismissNotification(notification._id)}
                className="text-red-500 hover:text-red-400 flex-none"
              />
            </div>
          ))}
        </>
      ) : (
        <div className="flex items-center space-x-2 py-1 cursor-pointer text-gray-500 italic">
          No new notifications
        </div>
      )}
    </div>
  );

  const announcementNotificationList = (
    <div className="divide-y w-80 ">
      {announcements?.data?.length ? (
        <>
          {announcements?.data?.slice(0, 7)?.map((announcement) => (
            <div
              key={announcement._id}
              className="flex items-center space-x-2 p-1 cursor-pointer hover hover:text-primary-500"
            >
              <ImNotification size={20} className="flex-none" />
              <div className="flex-grow">
                <div>{announcement?.title}</div>
                <div className="text-xs italic font-light">
                  {moment(announcement?.createdAt).format("Do MMM YYYY")}
                </div>
                <div
                  className="text-xs line-clamp-1"
                  style={{ wordBreak: "break-word" }}
                >
                  {announcement?.description}
                </div>
              </div>

              <RiCloseLine
                onClick={() => dismissNotification(announcement._id)}
                size={20}
                className="text-red-500 hover:text-red-400 flex-none"
              />
            </div>
          ))}
        </>
      ) : (
        <div className="flex items-center space-x-2 py-1 cursor-pointer text-gray-500 italic">
          No new announcements
        </div>
      )}
    </div>
  );

  function setUserImage(role) {
    return (
      <img
        className="w-8 h-8 rounded-full"
        src={
          user
            ? user[role == "instructor" ? "teacher" : role]?.profile_image
              ? cloudFrontUrl +
                "/" +
                user?._id +
                "/" +
                user[role == "instructor" ? "teacher" : role]?.profile_image
              : "https://ui-avatars.com/api/?name=" +
                user[role == "instructor" ? "teacher" : role]?.first_name +
                " " +
                user[role == "instructor" ? "teacher" : role]?.last_name
            : "https://ui-avatars.com/api/?name=TB"
        }
        alt="profile"
      />
    );
  }

  return (
    <div className="h-full bg-primary">
      <div className=" h-full flex flex-row items-center justify-between px-10 py-2">
        <div className="inline-flex flex-row items-center space-x-3 h-full">
          <div className=" inline-flex items-center bg-white rounded-md shadow px-2 py-1">
            <img className="w-10 h-10" src={logo} alt="logo" />
            <div className="text-primary-500 text-lg">{university}</div>
          </div>

          <div className="capitalize text-white font-normal text-lg">
            {user?.role === "super_admin" ? "Super Admin" : user?.role}{" "}
            Dashboard
          </div>
        </div>

        {/* ------------------------------------------------------------- */}

        <div className="inline-flex flex-row items-center space-x-2 ml-auto">
          {hideShowNotification ? (
            <Popover
              placement="bottom"
              content={
                <>
                  {" "}
                  <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                    {notificationList}
                  </div>
                  <div className="pt-3 flex space-x-4 items-center justify-center">
                    <div
                      onClick={notifiactionShow}
                      className="inline-block text-primary-500 opacity-100 hover:opacity-70 cursor-pointer"
                    >
                      View All
                    </div>
                    <div
                      onClick={() => dismissAllNotifications("notifications")}
                      className="inline-block text-red-500 opacity-100 hover:opacity-70 cursor-pointer"
                    >
                      Dismiss All
                    </div>
                  </div>
                </>
              }
              trigger="click"
              onVisibleChange={(visible) => {
                if (!visible && notificationCount) {
                  updateAllNotifications("notifications");
                }
              }}
            >
              <div className="w-10 h-10 rounded-full p-1 bg-primary-600 flex items-center justify-center opacity-75 hover:opacity-100 cursor-pointer">
                <Badge size="small" count={notificationCount}>
                  <VscBell className="text-white" size={25} />
                </Badge>
              </div>
            </Popover>
          ) : (
            <div className="w-10 h-10 rounded-full p-1 bg-primary-600 flex items-center justify-center opacity-75 hover:opacity-100 cursor-pointer">
              <Badge size="small" count={notificationCount}>
                <VscBell className="text-white" size={25} />
              </Badge>
            </div>
          )}

          {/*  notification list popup  */}
          <Modal
            title="All Notifications"
            visible={showAllNotifications}
            footer={null}
            onCancel={cancelNotification}
          >
            {notifications?.data?.map((notification) => (
              <div
                key={notification._id}
                className="flex items-center space-x-2 p-1 cursor-pointer hover hover:text-primary-500"
              >
                <ImNotification size={20} className="flex-none" />
                <div className="flex-grow">
                  <div>{notification?.title}</div>
                  <div
                    className="text-xs line-clamp-1"
                    style={{ wordBreak: "break-word" }}
                  >
                    {notification?.description}
                  </div>
                </div>

                <RiCloseLine
                  onClick={() => dismissNotification(notification._id)}
                  size={20}
                  className="text-red-500 hover:text-red-400 flex-none"
                />
              </div>
            ))}
          </Modal>
          {/*  notification list popup  */}

          {user?.role !== "admin" &&
            (hideShowAnnouncement ? (
              <Popover
                placement="bottom"
                content={
                  <>
                    {" "}
                    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                      {announcementNotificationList}
                    </div>
                    <div className="pt-3 flex space-x-4 items-center justify-center">
                      <div
                        onClick={announcementShow}
                        className="inline-block text-primary-500 opacity-100 hover:opacity-70 cursor-pointer"
                      >
                        View All
                      </div>
                      <div
                        onClick={() => dismissAllNotifications("announcement")}
                        className="inline-block text-red-500 opacity-100 hover:opacity-70 cursor-pointer"
                      >
                        Dismiss All
                      </div>
                    </div>
                  </>
                }
                trigger="click"
                onVisibleChange={(visible) => {
                  if (!visible && announcementCount) {
                    updateAllNotifications("announcement");
                  }
                }}
              >
                <div className="w-10 h-10 rounded-full p-1 bg-primary-600 flex items-center justify-center opacity-75 hover:opacity-100 cursor-pointer">
                  <Badge size="small" count={announcementCount}>
                    <HiOutlineSpeakerphone
                      className={`${"text-white"}`}
                      size={25}
                    />
                  </Badge>
                </div>
              </Popover>
            ) : (
              <div className="w-10 h-10 rounded-full p-1 bg-primary-600 flex items-center justify-center opacity-75 hover:opacity-100 cursor-pointer">
                <Badge size="small" count={announcementCount}>
                  <HiOutlineSpeakerphone
                    className={`${"text-white"}`}
                    size={25}
                  />
                </Badge>
              </div>
            ))}

          {/* announcement notification list popup  */}
          <Modal
            title="All Anouncements"
            visible={showAllAnnouncements}
            footer={null}
            onCancel={cancelAnnouncement}
          >
            {announcements?.data?.map((announcement) => (
              <div
                key={announcement._id}
                className="flex items-center space-x-2 p-1 cursor-pointer hover hover:text-primary-500"
              >
                <div className="flex-grow">
                  <div>{announcement?.title}</div>
                  {moment(announcement?.createdAt).format("Do MMM YYYY")}
                  <div
                    className="text-xs line-clamp-1"
                    style={{ wordBreak: "break-word" }}
                  >
                    {announcement?.description}
                  </div>
                </div>

                <RiCloseLine
                  onClick={() => dismissNotification(announcement._id)}
                  size={20}
                  className="text-red-500 hover:text-red-400 flex-none"
                />
              </div>
            ))}
          </Modal>
          {/* announcement notification list popup  */}

          <div>
            <Popover placement="bottom" content={dropdown} trigger="click">
              <div className="bg-white rounded-md text-black ">
                <div className="flex flex-row items-center space-x-2 cursor-pointer p-1 px-2">
                  <div>{setUserImage(user?.role)}</div>
                  <div className=" text-black">
                    {user &&
                      user[user?.role === "instructor" ? "teacher" : user?.role]
                        ?.first_name}
                  </div>
                  <div>
                    <IoChevronDown />
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
}
