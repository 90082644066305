import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";
import TemplateListing from "./FeeListing";
import FeeTable from "./FeeTable";

const FeeTemplate = () => {
  const { Search } = Input;
  const queryClient = useQueryClient();
  const { Option } = Select;
  const [form] = Form.useForm();
  const history = useHistory();
  const [search, setSearch] = useState(null);
  const [addTemplate, setAddTemplate] = useState(false);
  const [otherFields, setOtherFields] = useState([]);
  const [feeComponents, setFeeComponents] = useState();
  const [templateType, setTemplateType] = useState();
  const [getTemplateData, setGetTemplateData] = useState([]);
  const [postTemplateResponse, setPostTemplateResponse] = useState();
  const [destinationId, setDestinationId] = useState();
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [selectedYearr, setSelectedYearr] = useState(null);

  const [data, setData] = useState({
    tution: "",
  });
  const dateFormat = "YYYY";

  const [searchQuery, setSearchQuery] = useState("");

  const addOtherField = () => {
    setOtherFields([
      ...otherFields,
      { id: Date.now(), label: "", value: "" },
    ]);
  };

  const removeOtherField = (id) => {
    setOtherFields((prevFields) => prevFields.filter((item) => item.id !== id));
  };


  const handleFeeData = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };

  const handleOtherFieldChange = (value, id, field) => {
    setOtherFields((prevFields) =>
      prevFields.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  const calculateTotal = () => {
    const tution = parseFloat(data.tution) || 0;
    const other = parseFloat(data.other) || 0;
    const otherFieldsTotal = otherFields.reduce((total, field) => {
      const value = parseFloat(field.value) || 0;
      return total + value;
    }, 0);

    const total = tution + other + otherFieldsTotal;
    if (tution === 0 && other === 0 && otherFieldsTotal === 0) {
      form.setFieldsValue({ total: "" });
    } else {
      form.setFieldsValue({ total: total.toFixed(2) });
    }
  };

  //handle search

  const handleSearch = (value) => {
    setSearch(value);
  };
  const filteredData = getTemplateData.filter(
    (item) =>
      item?.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedYear === null || item?.year === selectedYear)
  );

  useEffect(() => {
    calculateTotal();
    if (data) {
      const concatenatedData = Object.entries(data).map(([key, value]) => ({
        label: key,
        value,
      }));
      const mergedFields = [...concatenatedData, ...otherFields];
      setFeeComponents(mergedFields);
    }
  }, [otherFields, data]);

  async function fetchTemplateData(type, selectedYear, search) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/admin/fee-template/list`,
      params: {
        type,
        year: selectedYear,
        search,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res?.data?.result;
  }

  // React Query hooks for fetching data
  const {
    data: tutionData,
    isLoading: isLoadingTution,
    error: errorTution,
  } = useQuery(["fee-template", "tution", selectedYear, searchQuery], () =>
    fetchTemplateData("tution", selectedYear, searchQuery)
  );

  const {
    data: accommodationData,
    isLoading: isLoadingAccommodation,
    error: errorAccommodation,
  } = useQuery(
    ["fee-template", "accommodation", selectedYear, searchQuery],
    () => fetchTemplateData("accommodation", selectedYear, searchQuery)
  );
  const {
    data: transportationData,
    isLoading: isLoadingTransportation,
    error: errorTransportation,
  } = useQuery(
    ["fee-template", "transportation", selectedYear, searchQuery],
    () => fetchTemplateData("transportation", selectedYear, searchQuery)
  );

  const fetchRoute = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/routes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const route = useQuery("route", fetchRoute);
  async function fetchSubjects(destination) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/${destination}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const designationData = useQuery(
    ["subjects", destinationId],
    () => destinationId && fetchSubjects(destinationId),
    {
      enabled: !!destinationId,
    }
  );

  function disabledDatee(current) {
    return current && current < moment().startOf("day");
  }
  function disabledDate(current) {
    if (selectedYearr) {
      return current && current < moment(`${selectedYearr}-01-01`);
    }
    return current && current < moment().startOf("day");
  }

  const onFinish = (values) => {
    const templatePayload = {
      type: templateType,
      class: values?.class,
      route_id: values?.route,
      destination_id: values?.destination,
      title: values?.title,
      fee_components: feeComponents
        .filter(
          (component) =>
            component.label &&
            component.value &&
            parseFloat(component.value) > 0
        )
        .map((component) => ({
          name: component.label,
          amount: parseFloat(component.value),
        })),

      due_date: values?.due_date?.format("YYYY-MM-DD"),
      year: values?.year?.format("YYYY"),
      installment_count: values.installment_count,
    };
    axios
      .post(serverUrl + "/admin/fee-template/", templatePayload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        queryClient.invalidateQueries(["fee-template"]);
        setPostTemplateResponse(res);
        message.success(res?.data?.result?.message);
      })
      .catch((err) => {
        if (err?.response?.data?.error?.errors) {
          message.error(err?.response?.data?.error?.errors);
        }
      });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${serverUrl}/admin/fee-template/${id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      });
      queryClient.invalidateQueries(["fee-template"]);
      message.success("Template deleted successfully");
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };

  return (
    <>
      <div className="template">
        <div className="pt-8">
          <div className="bg-white p-10 rounded-2xl">
            <div className="text-2xl text-orange-500 font-semibold pb-8">
              Manage Fee
            </div>
            <div className="flex justify-between pb-4">
              <DatePicker
                picker="year"
                onChange={(date, dateString) => setSelectedYear(dateString)}
                defaultValue={moment()}
                format={dateFormat}
              />
              <Search
                placeholder="input search text"
                enterButton="Search"
                size="large"
                loading={false}
                style={{ backgroundColor: "#E3E3E3" }}
                prefix={<BiSearch className="text-gray-600 " />}
                onSearch={(value) => handleSearch(value)}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-310"
              />
            </div>

            <TemplateListing
              type="tution"
              filteredData={tutionData}
              handleDelete={handleDelete}
              setAddTemplate={setAddTemplate}
              setTemplateType={setTemplateType}
            />
            <TemplateListing
              type="transportation"
              handleDelete={handleDelete}
              filteredData={transportationData}
              setAddTemplate={setAddTemplate}
              setTemplateType={setTemplateType}
            />
            <TemplateListing
              type="accommodation"
              filteredData={accommodationData}
              handleDelete={handleDelete}
              setAddTemplate={setAddTemplate}
              setTemplateType={setTemplateType}
            />
          </div>
        </div>

        {/* ----------------------------------modal------------------------------------ */}
        {/* Add Template */}
        <Modal
          width={800}
          visible={addTemplate}
          onOk={() => setAddTemplate(false)}
          onCancel={() => {
            form.resetFields();
            setAddTemplate(false);
            setOtherFields([]);
            setPostTemplateResponse(null);
            form.setFieldsValue({ total: "" });
            setFeeComponents();
          }}
          footer={null}
          forceRender
        >
          <div>
            <h3 className="text-lg font-bold pb-3">Add Template</h3>
            <div className="pt-4 w-4/5">
              <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
              >
                {templateType !== "transportation" && (
                  <Form.Item
                    label="Class"
                    name="class"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please select a class",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Class"
                      className="w-full bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {classes?.isSuccess &&
                        classes?.data?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {data.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  label="Title"
                  name="title"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter title" />
                </Form.Item>
                {templateType === "transportation" && (
                  <>
                    <Form.Item
                      label="Route"
                      name="route"
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "Please select a route",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select route"
                        className="w-full bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(value) => {
                          setDestinationId(value);
                        }}
                      >
                        {route?.isSuccess &&
                          route?.data?.result?.data?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Destination"
                      name="destination"
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "Please select destination",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select destination"
                        className="w-full bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {designationData?.isSuccess &&
                          designationData?.data?.result?.data?.route_map?.map(
                            (data) => {
                              return (
                                <Option key={data._id} value={data._id}>
                                  {data.name}
                                </Option>
                              );
                            }
                          )}
                      </Select>
                    </Form.Item>
                  </>
                )}

                <Form.Item
                  name="tution"
                  label="Fee"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Fee is required",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    onChange={(e) => {
                      handleFeeData("tution", e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter amount"
                  />
                </Form.Item>
                {/* {otherFields?.map((field, index) => (
                  <div key={index} className=" space-x-5 mb-2">
                    <h6 key={index} className="mb-8px capitalize">
                      {field?.label}
                    </h6>
                    <div className="flex ml-0px space-x-4 other-feild">
                      <Form.Item
                        name={`otherField_${index}`}
                        label={
                          <Input
                            value={field.label}
                            onChange={(e) => {
                              const value = e.target.value;
                              const capitalizedValue =
                                value.charAt(0).toUpperCase() + value.slice(1);
                              handleOtherFieldChange(
                                capitalizedValue,
                                index,
                                "label"
                              );
                            }}
                            onKeyDown={(e) => {
                              if (
                                e.key === "ArrowUp" ||
                                e.key === "ArrowDown"
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            placeholder="Enter title"
                            className="w-24 mr-2 flex"
                            rules={[
                              {
                                required: true,
                                message: "Label is required",
                              },
                            ]}
                          />
                        }
                        className="w-30"
                      >
                        <Input
                          onChange={(e) =>
                            handleOtherFieldChange(
                              e.target.value,
                              index,
                              "value"
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => e.target.blur()}
                          className="mr-2"
                          placeholder="Enter amount"
                          type="number"
                          min={0}
                        />
                      </Form.Item>
                      <Button
                        type="danger"
                        onClick={() => removeOtherField(index)}
                        className="ml-2"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ))} */}
                {otherFields?.map((field) => (
                  <div key={field.id} className="space-x-5 mb-2">
                    <h6 className="mb-8px capitalize">{field?.label}</h6>
                    <div className="flex ml-0px space-x-4 other-feild">
                      <Form.Item
                        name={`label_${field.id}`}
                        label={
                          <Input
                            value={field.label}
                            onChange={(e) =>
                              handleOtherFieldChange(
                                e.target.value.charAt(0).toUpperCase() +
                                  e.target.value.slice(1),
                                field.id,
                                "label"
                              )
                            }
                            placeholder="Enter title"
                            className="w-24 mr-2 flex"
                          />
                        }
                        className="w-30"
                      >
                        <Input
                          value={field.value}
                          onChange={(e) =>
                            handleOtherFieldChange(
                              e.target.value,
                              field.id,
                              "value"
                            )
                          }
                          className="mr-2"
                          placeholder="Enter amount"
                          type="number"
                          min={0}
                        />
                      </Form.Item>
                      <Button
                        type="danger"
                        onClick={() => removeOtherField(field.id)}
                        className="ml-2"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ))}

                <div className="pb-4">
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={addOtherField}
                    className="mb-2"
                  >
                    Add
                  </Button>
                </div>
                <Form.Item name="total" label="Total Amount" className="w-30">
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="year"
                  label="Year"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Year is required",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    onChange={(value) => {
                      form.setFieldsValue({ due_date: null });
                      setSelectedYearr(value ? moment(value).year() : null);
                    }}
                    disabledDate={disabledDatee}
                  />
                </Form.Item>
                <Form.Item
                  name="due_date"
                  label="Due Date"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Due Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  name="installment_count"
                  label="Number of terms (Max 10)"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Number of terms is required",
                    },
                    {
                      validator: (_, value) => {
                        if (value && (value < 1 || value > 10)) {
                          return Promise.reject(
                            "Number of terms should be between 1 and 10"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Enter number of terms"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
                <div className="flex justify-center items-center py-5">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
            {templateType !== "transportation" && (
              <FeeTable
                postTemplateResponse={postTemplateResponse}
                templateType={templateType}
              />
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default FeeTemplate;
