import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tag,
  Typography,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsPencil } from "react-icons/bs";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";
const { Option } = Select;

function tagRender(props) {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ margin: 5 }}
      className="flex items-center"
    >
      {label}
    </Tag>
  );
}
const BloodGroupSelect = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Blood Group"
      getPopupContainer={(trigger) => trigger.parentNode}
      allowClear
    >
      <Option value="A+">A+</Option>
      <Option value="B+">B+</Option>
      <Option value="O+">O+</Option>
      <Option value="A-">A-</Option>
      <Option value="B-">B-</Option>
      <Option value="O-">O-</Option>
      <Option value="AB+">AB+</Option>
      <Option value="AB-">AB-</Option>
    </Select>
  );
};

function InstructorSetting({ data }) {
  const { Option } = Select;
  const { confirm } = Modal;
  const { Title } = Typography;
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const [emailDisable, setEmailDisable] = useState(true);
  const [transportationCheckbox, setTransportationCheckbox] = useState(false);
  const [routeId, setRouteId] = useState();

  //get routes
  async function fetchRoute() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routes = useQuery(["routes"], () => fetchRoute());

  async function fetchRouteDetails(routeId) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/${routeId}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const routesDetail = useQuery(
    ["routes", routeId],
    () => routeId && fetchRouteDetails(routeId),
    {
      enabled: !!routeId,
    }
  );

  const fetchSubjects = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/subjects/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };

  const subjects = useQuery("subjects", fetchSubjects);

  // fetch departments
  async function fetchDepartments() {
    const response = await axios.get(serverUrl + "/department", {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return response.data;
  }
  const departments = useQuery("departments", fetchDepartments);

  function showConfirm(values) {
    confirm({
      title: "Do you Want to change the details?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        updateProfile(values);
      },
      onCancel() {},
    });
  }

  const updateProfile = (values) => {
    const payload = {
      first_name: values.first_name,
      last_name: values?.last_name,
      subjects: values?.subjects,
      title: values?.title,
      department: values?.department,
      is_transportation_required: transportationCheckbox,
      route_id: values.route_id,
      route_entry_point_id: values?.route_entry_point_id,
      dob: values?.dob,
      employment_started_at: values?.employment_started_at,
      employment_ended_at: values?.employment_ended_at,
      aadhar: values?.aadhar,
      address: values?.address,
      blood_group: values?.blood_group,
    };
    if (values.password) {
      axios({
        method: "patch",
        url: serverUrl + "/users/password/" + data?._id,
        data: {
          email: values.email,
          password: values.password ? values.password : null,
          blocked: values.blocked,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
        .then((res) => {
          axios
            .patch(serverUrl + "/teachers/" + data.teacher._id, payload, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "jwt-token"
                )}`,
              },
            })
            .then((res) => {
              message.success("Updated");
              queryClient.invalidateQueries(["instructor", data?._id]);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .patch(serverUrl + "/teachers/" + data.teacher._id, payload, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        })
        .then((res) => {
          axios
            .patch(
              serverUrl + "/users/" + data._id,
              {
                blocked: values.blocked,
                email: values.email,
                phone: values?.phone,
              },
              {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "jwt-token"
                  )}`,
                },
              }
            )
            .then((res) => {
              message.success("Updated");
              queryClient.invalidateQueries(["instructor", data?._id]);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const confirmDelete = () => {
    confirm({
      title: "Do you Want to delete the instructor?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk() {
        deleteUser();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteUser = () => {
    axios({
      method: "delete",
      url: serverUrl + "/users/" + data?._id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((res) => {
      message.success("Deleted");
      history.push(`/dashboard/admin/menu/instructor`);
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      email: data?.email,
      phone: data?.phone,
      first_name: data?.teacher?.first_name,
      last_name: data?.teacher?.last_name,
      title: data?.teacher?.title,
      department: data?.teacher?.department?._id,
      subjects: data?.teacher?.subjects.map((s) => s._id),
      blocked: data?.blocked,
      dob: data?.teacher?.dob && moment(data?.teacher?.dob, dateFormat),
      employment_started_at:
        data?.teacher?.employment_started_at &&
        moment(data?.teacher?.employment_started_at, dateFormat),
      employment_ended_at:
        data?.teacher?.employment_ended_at &&
        moment(data?.teacher?.employment_ended_at, dateFormat),
      aadhar: data?.teacher?.aadhar,
      address: data?.teacher?.address,
      blood_group: data?.teacher?.blood_group,
      is_transportation_required: data?.is_transportation_required,
      route_id: data?.route_id?._id,
      route_entry_point_id: data?.route_entry_point_id?._id,
    });
    setRouteId(data?.route_id?._id);
    setTransportationCheckbox(data?.is_transportation_required);
  }, [data]);
  return (
    <>
      <div className="pt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          {data && (
            <Form
              form={form}
              layout="vertical"
              name="basic"
              onFinish={showConfirm}
              autoComplete="off"
            >
              <div className="grid grid-cols-2 gap-x-4">
                <div className="flex flex-row items-center space-x-8">
                  <Form.Item
                    label="Email Address"
                    name="email"
                    className="w-full"
                  >
                    <Input
                      className="w-full"
                      type="email"
                      disabled={emailDisable}
                    />
                  </Form.Item>
                  <div
                    className="flex items-center justify-center p-2 bg-gray-300 rounded-full cursor-pointer"
                    onClick={() => setEmailDisable(false)}
                  >
                    <BsPencil />
                  </div>
                </div>
                <div className="flex flex-row items-center space-x-8 ">
                  <Form.Item
                    label="Password"
                    name="password"
                    className="w-full"
                    rules={[
                      {
                        min: 6,
                        message: "Password must be at least 6 characters long!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="********" className="w-full" />
                  </Form.Item>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 10)}
                  className="w-full"
                >
                  <Input type="number" maxLength={10} min={0} />
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                    {
                      message:
                        "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                      validator: (_, value) => {
                        if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                    {
                      message:
                        "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                      validator: (_, value) => {
                        if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                          );
                        }
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Title" name="title" className="w-full">
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Department"
                  name="department"
                  className="w-full"
                >
                  <Select
                    showSearch
                    showArrow
                    allowClear
                    placeholder="Select Department"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    className="w-full"
                  >
                    {departments.isSuccess &&
                      departments?.data?.map((data) => {
                        return (
                          <Option key={data._id} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="aadhar"
                  label="Aadhar"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please enter your aadhar id",
                    },
                    {
                      pattern: /^[0-9]{12}$/, // Updated regex pattern to match 12 digits
                      message: "Please enter a valid 12-digit Number!",
                    },
                  ]}
                  getValueFromEvent={(e) => e.target.value.slice(0, 12)}
                >
                  <Input maxLength={12} type="number" min={0} />
                </Form.Item>
                <Form.Item
                  name="dob"
                  label="Date Of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Date of Birth  is required",
                    },
                  ]}
                  className="w-full"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Please select your date of birth"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="employment_started_at"
                  label="Employment Start Date"
                  className="w-full"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Please select"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="employment_ended_at"
                  label="Employment End Date"
                  className="w-full"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Please select"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Address"
                  className="w-30"
                  getValueFromEvent={(e) => e.target.value}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="blood_group"
                  label="Blood Group"
                  className="w-30"
                >
                  <BloodGroupSelect />
                </Form.Item>

                <div>
                  <div className="pb-2">Subjects Allowed </div>
                  <Form.Item name="subjects" className="w-full">
                    <Select
                      mode="multiple"
                      showArrow
                      allowClear
                      disabled={data.blocked}
                      tagRender={tagRender}
                      placeholder="Select Subjects"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {subjects.isSuccess &&
                        subjects?.data
                          ?.filter(
                            (s) =>
                              !s.teacher || s.teacher._id === data?.teacher?._id
                          )
                          .map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data?.class?.name + " " + data.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <Title level={2}>Transportation</Title>
              <Form.Item
                name="is_transportation_required"
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) => {
                    setTransportationCheckbox(e.target.checked);
                    form.resetFields(["route_id", "route_entry_point_id"]);
                  }}
                  checked={transportationCheckbox}
                >
                  Transportation require?
                </Checkbox>
              </Form.Item>
              {transportationCheckbox && (
                <div className="admission-form">
                  <Form.Item label="Route" name="route_id" className="w-full">
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Route"
                      className=" bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value) => {
                        setRouteId(value);
                        form.resetFields(["route_entry_point_id"]);
                      }}
                    >
                      {routes?.isSuccess &&
                        routes?.data?.result?.data?.map((data) => {
                          return (
                            <Option key={data._id} value={data._id}>
                              {data?.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Route Entry Point"
                    name="route_entry_point_id"
                    className="w-full"
                  >
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Route Entry Point"
                      className="w-full bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {routesDetail?.isSuccess &&
                        routesDetail?.data?.result?.data?.route_map?.map(
                          (data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data.name}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  </Form.Item>
                </div>
              )}

              <div>
                <div className="pt-4 pb-2">Instructor Status</div>
                <Form.Item className="w-96" name="blocked">
                  <Select
                    showSearch
                    showArrow
                    allowClear
                    placeholder="Instructor Status"
                    optionFilterProp="children"
                  >
                    <Option value={false} className="text-green-600">
                      Active
                    </Option>
                    <Option value={true} className="text-red-600">
                      Suspend
                    </Option>
                  </Select>
                </Form.Item>
              </div>

              <div className="flex items-center justify-between pt-10 ">
                <div>
                  <Button size="large" type="danger" onClick={confirmDelete}>
                    Delete
                  </Button>
                </div>
                <div className="flex flex-row space-x-4">
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
}

export default InstructorSetting;
