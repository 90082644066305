import { DeleteOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Table,
} from "antd";
import Button from "antd-button-color";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";

function Syllabus({ data }) {
  const user = useSelector(selectUser);
  const isHide = user?.role !== "instructor";
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [type, setType] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const [addSchool, SetAddSchool] = useState(false);
  const [showSyllabus, SetShowSyllabus] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchSyllabus = async (subjectId) => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/syllabus",
      params: {
        subjectId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const getSyllabus = useQuery(
    ["syllabus", data?._id],
    () => fetchSyllabus(data?._id),
    {
      enabled: !!data?._id,
    }
  );

  const columns = [
    {
      title: "Order",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base  text-center capitalize"
        >
          {`${record?.order}`}
        </div>
      ),
      onFilter: (value, record) =>
        record?.first_name?.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Name",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base  text-center capitalize"
        >
          {`${record?.name}`}
        </div>
      ),
      onFilter: (value, record) =>
        record?.first_name?.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Type",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base  text-center capitalize"
        >
          {record?.type}
        </div>
      ),
    },
    {
      title: "Details",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base text-center capitalize"
        >
          <div className="flex flex-col gap-2 flex-start ">
            <div>
              {record?.start_date &&
                `Start Date:${moment(record?.start_date).format("DD/MM/YYYY")}`}
            </div>
            <div>
              {record?.end_date &&
                `Due Date:${moment(record?.end_date).format("DD/MM/YYYY")}`}
            </div>
            <div>{record?.score && `Score: ${record?.score}`}</div>
            <div>{record?.weightage && `weightage: ${record?.weightage}`}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record, index) => (
        <div
          key={record?._id}
          className=" text-base font-semibold text-center text-blue-500 "
        >
          <div
            onClick={() => {
              setIsEditMode(true);
              setEditData({ [index?._id]: record });
              SetAddSchool(true);
            }}
            className="cursor-pointer"
          >
            View
          </div>

          {!isHide && (
            <div className="text-center flex justify-center pt-2">
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={() => deleteSyllabus(record?._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="danger flex items-center justify-center"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          )}
        </div>
      ),
    },
  ];

  const onFinish = async (item) => {
    try {
      let payload;

      if (item.type === "exam" || item.type === "assignment") {
        payload = {
          order: Number(item.order),
          score: Number(item.score) || null,
          weightage: Number(item.weightage) || null,
          name: item.name,
          type: item.type,
          subject: data?._id,
          start_date: null,
          end_date: item.dueDate?.format("YYYY-MM-DD"),
        };
      } else if (item.type === "module") {
        payload = {
          order: Number(item.order),
          score: null,
          weightage: null,
          name: item.name,
          type: item.type,
          subject: data?._id,
          start_date: item.startDate?.format("YYYY-MM-DD"),
          end_date: item.endDate?.format("YYYY-MM-DD"),
        };
      }

      if (payload) {
        if (isEditMode) {
          axios
            .put(`${serverUrl}/syllabus/${Object.keys(editData)[0]}`, payload, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "jwt-token"
                )}`,
              },
            })
            .then((res) => {
              form.resetFields();
              SetAddSchool(false);
              setEditData({});
              setIsEditMode(false);
              setType(null);
              queryClient.invalidateQueries(["syllabus"]);
              message.success(res?.data?.result?.message);
            })
            .catch((err) => {
              if (err?.response?.data?.error?.errors) {
                message.error(err?.response?.data?.error?.errors);
              }
            });
        } else {
          axios
            .post(`${serverUrl}/syllabus`, payload, {
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "jwt-token"
                )}`,
              },
            })
            .then((res) => {
              queryClient.invalidateQueries(["syllabus"]);
              form.resetFields();
              SetAddSchool(false);
              setType(null);
              message.success(res?.data?.result?.message);
            })
            .catch((err) => {
              if (err?.response?.data?.message) {
                message.error(err?.response?.data?.message);
              }
            });
        }
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        message.error(err?.response?.data?.message);
      }
    }
  };

  function deleteSyllabus(id) {
    axios
      .delete(serverUrl + "/syllabus/" + id, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      })
      .then((res) => {
        message.success("syllabus Deleted");
        queryClient.invalidateQueries(["syllabus"]);
      });
  }

  useEffect(() => {
    if (isEditMode) {
      form.setFieldsValue({
        order: Number(editData[Object.keys(editData)[0]]?.order),
        name: editData[Object.keys(editData)[0]]?.name,
        type: editData[Object.keys(editData)[0]]?.type,
      });
      if (editData[Object.keys(editData)[0]]?.type) {
        setType(editData[Object.keys(editData)[0]]?.type);
      }
      if (
        editData[Object.keys(editData)[0]]?.type === "exam" ||
        editData[Object.keys(editData)[0]]?.type === "assignment"
      ) {
        form.setFieldsValue({
          score: editData[Object.keys(editData)[0]]?.score,
          weightage: editData[Object.keys(editData)[0]]?.weightage,
          dueDate: editData[Object.keys(editData)[0]]?.end_date
            ? moment(editData[Object.keys(editData)[0]]?.end_date)
            : null,
        });
      }
      if (editData[Object.keys(editData)[0]]?.type === "module") {
        form.setFieldsValue({
          startDate: editData[Object.keys(editData)[0]]?.start_date
            ? moment(editData[Object.keys(editData)[0]]?.start_date)
            : null,
          endDate: editData[Object.keys(editData)[0]]?.end_date
            ? moment(editData[Object.keys(editData)[0]]?.end_date)
            : null,
        });
      }
    }
  }, [isEditMode]);

  const downloadColumns = [
    {
      title: <span className="font-bold">Syllabus Name</span>,
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span className="capitalize cursor-pointer hover:text-blue-500">
          {text || "Unnamed Syllabus"}
        </span>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleMultiDownload = () => {
    if (selectedRowKeys.length === 0) {
      alert("Please select at least one syllabus to download.");
      return;
    }
    handleDownload(selectedRowKeys);
  };

  const handleDownload = async (selectedIds) => {
    const payload = {
      syllabusIds: selectedIds.length > 0 ? selectedIds : [],
    };

    if (selectedIds.length > 0) {
      try {
        const response = await axios.post(
          `${serverUrl}/syllabus/export`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        const filename =
          response.headers["content-disposition"]?.match(
            /filename="(.+)"/
          )?.[1] || "syllabus-export.pdf";
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setSelectedRowKeys([]);
        SetShowSyllabus(false);
      } catch (err) {
        if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        } else {
          message.error("Failed to download the syllabus.");
        }
      }
    } else {
      message.warning("Please select at least one syllabus to download.");
    }
  };

  return (
    <div className=" pt-8 ">
      <div className="bg-white p-6 rounded-xl">
        <div className="flex  justify-end gap-4 ">
          <Button
            onClick={() => SetShowSyllabus(true)}
            size="large"
            type="primary"
          >
            Download
          </Button>
          {!isHide && (
            <div className="flex justify-end">
              <Button
                size="large"
                type="primary"
                htmlType="download"
                className="text-white w-9"
                onClick={() => SetAddSchool(true)}
              >
                Add
              </Button>
            </div>
          )}
        </div>

        <div className="py-5 table-overflow">
          <Table
            columns={columns}
            dataSource={
              getSyllabus?.data?.result.length > 0 && getSyllabus?.data?.result
                ? getSyllabus?.data?.result
                : []
            }
            pagination={true}
            loading={getSyllabus?.isLoading}
          />
        </div>
        <Modal
          width={600}
          visible={addSchool}
          onCancel={() => {
            SetAddSchool(false);
            setIsEditMode(false);
            form.resetFields();
          }}
          footer={null}
        >
          <div>
            {/* ---------------------------form---------------- */}
            <h3 className="text-lg font-bold pb-3">
              {isHide
                ? "Syllabus Overview"
                : isEditMode
                ? "Update Syllabus"
                : "Add Syllabus"}
            </h3>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="order"
                label="Order"
                rules={[{ required: true, message: "Please input the order!" }]}
              >
                <Input
                  type="number"
                  placeholder="Enter Order"
                  readOnly={isHide}
                />
              </Form.Item>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: "Please input First name" },
                  {
                    message:
                      "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed.",
                    validator: (_, value) => {
                      if (/^[a-zA-Z][a-zA-Z\s]*$/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          "Name must start with a letter and can only contain letters. Numbers and symbols are not allowed."
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  className="w-full"
                  placeholder="Enter name"
                  readOnly={isHide}
                />
              </Form.Item>
              <Form.Item
                name="type"
                label="Type"
                showArrow
                rules={[{ required: true, message: "Please select a type!" }]}
              >
                <Select
                  placeholder="Select type"
                  onChange={(value) => setType(value)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={isHide}
                >
                  <Select.Option value="module">Module</Select.Option>
                  <Select.Option value="exam">Exam</Select.Option>
                  <Select.Option value="assignment">Assignment</Select.Option>
                </Select>
              </Form.Item>
              {(type === "exam" || type === "assignment") && (
                <>
                  <Form.Item
                    name="dueDate"
                    label="Due Date"
                    rules={[
                      { required: true, message: "Please select a due date!" },
                    ]}
                  >
                    <DatePicker
                      className="w-full"
                      disabled={isHide}
                      format="DD-MM-YYYY"
                    />
                  </Form.Item>

                  <Form.Item
                    name="score"
                    label="Score"
                    rules={[
                      { required: true, message: "Please input the score!" },
                      {
                        validator: (_, value) =>
                          value >= 0
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Score must be a positive value!")
                              ),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Enter score"
                      readOnly={isHide}
                    />
                  </Form.Item>

                  <Form.Item
                    name="weightage"
                    label="Weightage"
                    rules={[
                      {
                        required: true,
                        message: "Please input the weightage!",
                      },
                      {
                        validator: (_, value) =>
                          value >= 0 && value <= 100
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  "Weightage must be between 0 and 100!"
                                )
                              ),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Enter weightage"
                      readOnly={isHide}
                    />
                  </Form.Item>
                </>
              )}

              {type === "module" && (
                <div style={{ display: "flex", gap: "8px" }}>
                  <Form.Item
                    name="startDate"
                    label="Start Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select a start date!",
                      },
                    ]}
                    style={{ flex: 1 }}
                  >
                    <DatePicker
                      placeholder="Start Date"
                      className="w-full"
                      format="DD-MM-YYYY"
                      onChange={(date) => {
                        form.setFieldsValue({ endDate: null });
                      }}
                      disabled={isHide}
                    />
                  </Form.Item>
                  <Form.Item
                    name="endDate"
                    label="End Date"
                    format="DD/MM/YYYY"
                    rules={[
                      {
                        required: true,
                        message: "Please select an end date!",
                      },
                    ]}
                    style={{ flex: 1 }}
                  >
                    <DatePicker
                      placeholder="End Date"
                      className="w-full"
                      format="DD-MM-YYYY"
                      disabled={isHide}
                      disabledDate={(current) => {
                        const startDate = form.getFieldValue("startDate");
                        return (
                          current &&
                          startDate &&
                          current.isBefore(startDate, "day")
                        );
                      }}
                    />
                  </Form.Item>
                </div>
              )}
              {!isHide && (
                <div className="flex justify-end items-center pt-6">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      size="large"
                      type="dark"
                      onClick={() => {
                        SetAddSchool(false);
                        setType(null);
                        form.resetFields();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                    >
                      {isEditMode ? "Update" : " Save"}
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </Modal>
        <Modal
          width={600}
          visible={showSyllabus}
          onCancel={() => {
            SetShowSyllabus(false);
            setSelectedRowKeys([]);
          }}
          footer={
            <Button
              type="primary"
              onClick={handleMultiDownload}
              disabled={selectedRowKeys.length === 0}
            >
              Download
            </Button>
          }
        >
          <h3 className="text-lg font-bold pb-3">Syllabus List</h3>

          {getSyllabus?.data?.result && getSyllabus?.data?.result.length > 0 ? (
            <Table
              rowSelection={rowSelection}
              columns={downloadColumns}
              dataSource={getSyllabus.data.result.map((item) => ({
                key: item._id, // Ensure unique keys for rows
                name: item.name,
              }))}
              pagination={{ pageSize: 5 }}
            />
          ) : (
            <p>No Syllabus Found</p>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default Syllabus;
