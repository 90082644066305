import { Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { serverUrl } from "../../../../../nestserver";
import Exam from "../../../../instructor/pages/Exam";

const ManageExam = () => {
  const { Option } = Select;
  const [classId, setClassId] = useState();
  const [subjectId, setSubjectId] = useState();
  const handleSelectChange = (classId) => {
    setSubjectId(null);
    setClassId(classId);
  };
  const handleSelectSubjectChange = (classId) => {
    setSubjectId(classId);
  };


  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  async function fetchInstructor() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/" + classId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  }

  const subjects = useQuery(["subject", classId], fetchInstructor, {
    enabled: !!classId,
  });

  return (
    <div>
      <div className="my-8 px-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl pb-4 text-orange-500 font-semibold">
            Manage Exam
          </div>
          <div className="flex gap-4">
            <div className="flex flex-col w-310">
              <h3 className="text-base pb-3"> Class*</h3>
              <Select
                showSearch
                showArrow
                placeholder="Select Class"
                className="w-full bg-gray-300"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectChange(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {classes?.isSuccess &&
                  classes?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data?.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className="flex flex-col w-310">
              <h3 className="text-base pb-3"> Subjects*</h3>
              <Select
                showSearch
                showArrow
                value={subjectId}
                placeholder="Select Subject"
                className="w-full bg-gray-300"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectSubjectChange(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {subjects?.isSuccess &&
                  subjects?.data?.subjects?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data?.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="">
            {classId && subjectId && (
              <Exam subjectId={subjectId} classId={classId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageExam;
