import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import CreateAssignmentForm from "../../../components/forms/CreateAssignmentForm";
import AssignmentsTable from "../../../components/tables/AssignmentsTable";
import EditAssignment from "./EditAssignment";
import EditExam from "./EditExam";

function Assignment({
  subjectId = null,
  classId = null,
  canCreate = false,
  onCallback = console.log,
}) {
  const [createassignment, setCreateAssignment] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selected) {
      onCallback(false);
    } else {
      onCallback(true);
    }
  }, [selected]);

  return (
    <>
      {selected ? (
        <>
          {selected.type === "assignment" ? (
            <EditAssignment
              id={selected?.id}
              onBack={() => setSelected(null)}
            />
          ) : (
            <EditExam id={selected?.id} onBack={() => setSelected(null)} />
          )}
        </>
      ) : (
        <div className="py-10 ">
          <div
            style={{ borderRadius: "10px", color: "#616161" }}
            className=" bg-white drop-shadow-md w-full "
          >
            <div className="h-16 ">
              <div
                style={{ backgroundColor: "#09617E" }}
                className="uppercase rounded-tl-xl text-white rounded-tr-xl h-full 
                            font-normal text-base flex items-center justify-center relative"
              >
                <h1 className="text-white font-bold">ASSIGNMENTS </h1>
                {canCreate && (
                  <div
                    className="absolute items-center justify-center top-1/4 right-6"
                  >
                    <Button
                      onClick={() => setCreateAssignment(true)}
                      className="w-40"
                      type="secondary"
                    >
                      Create Assignment
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <Modal
              width={800}
              title="Create Assignment"
              visible={createassignment}
              // onOk={() => setCreateAssignment(false)}
              onCancel={() => setCreateAssignment(false)}
              footer={null}
            >
              <CreateAssignmentForm
                SubjectId={subjectId}
                ClassId={classId}
                onCancel={() => setCreateAssignment(false)}
              />
            </Modal>

            <div className="">
              <AssignmentsTable
                onClick={(data) => setSelected(data)}
                subjectId={subjectId}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Assignment;
