import { DeleteOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router";
import { defaultStatus } from "../../../../../helper/options";
import plusIcon from "../../../../../resources/plus.svg";

const TemplateListing = ({
  filteredData,
  handleDelete,
  setAddTemplate,
  type,
  setTemplateType,
}) => {
  const history = useHistory();
  return (
    <div>
      <div className="text-2xl text-orange-500 font-semibold pb-4 pt-4">
        {type === "tution" ? (
          <p>Manage Tuition</p>
        ) : type === "accommodation" ? (
          <p>Manage Accommodation</p>
        ) : type === "transportation" ? (
          <p>Manage Transportation</p>
        ) : (
          <p>Invalid Type</p>
        )}
      </div>
      <div className="flex justify-between items-start pt-4">
        <div className="flex gap-20px flex-wrap	">
          {filteredData && filteredData?.length > 0 ? (
            filteredData.map((list) => (
              <div>
                <div
                  className="iskool-friends-card flex flex-col space-x-3 cursor-pointer p-20px relative "
                  key={list?._id}
                  style={{ width: "155px", height: "150px" }}
                >
                  <span
                    className={clsx(
                      list?.status === defaultStatus?.applied
                        ? "bg-green"
                        : "bg-yellow",
                      "p-1 rounded-lg text-center"
                    )}
                    onClick={() =>
                      history.push(
                        `/dashboard/admin/menu/fee/template/${list?._id}?type=${type}`
                      )
                    }
                  >
                    {list?.status === defaultStatus?.applied
                      ? "Applied "
                      : "Pending"}
                  </span>
                  {type === "accommodation" && (
                    <Tooltip
                      title={`Applied Count: ${
                        list?.appliedCount ? list?.appliedCount : 0
                      }`}
                    >
                      <div
                        className="absolute border flex justify-center items-center rounded-full h-5 w-5"
                        style={{ top: "-2px", right: "-1px",width:"20px",height:"20px" }}
                      >
                        {list?.appliedCount ? list?.appliedCount : 0}
                      </div>
                    </Tooltip>
                  )}
                  <div
                    className="text-center mt-14px"
                    onClick={() =>
                      history.push(
                        `/dashboard/admin/menu/fee/template/${list?._id}?type=${type}`
                      )
                    }
                  >
                    <UnorderedListOutlined size={35} />
                    <div className="pt-2">
                      <div className="font-bold line-clamp-2">{list?.name}</div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    {list?.status !== "applied" && (
                      <div className="">
                        <Popconfirm
                          title="Are you sure to delete?"
                          onConfirm={() => handleDelete(list?._id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="danger flex items-center justify-center"
                            shape="circle"
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>No Template Exist</div>
          )}
        </div>
        <div
          onClick={() => {
            setAddTemplate(true);
            setTemplateType(type);
          }}
          className="cursor-pointer add-module flex items-center justify-center mt-2"
          style={{ padding: "46px 29px" }}
        >
          <div className="flex flex-col items-center space-y-3">
            <div className="flex items-center justify-center cursor-pointer">
              <img className="w-6 h-6" src={plusIcon} alt="icon" />
            </div>
            <div
              className="text-white text-sm flex flex-col items-center justify-center"
              style={{ width: "200px" }}
            >
              <p className="flex flex-col items-center justify-center">
                {" "}
                {type === "tution" ? (
                  <span> Tuition Template</span>
                ) : type === "accommodation" ? (
                  <span> Accommodation Template</span>
                ) : type === "transportation" ? (
                  <span> Transportation Template</span>
                ) : (
                  <span>Invalid Type</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateListing;
