import axios from "axios";
import * as moment from "moment";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { serverUrl } from "../../../../nestserver";
import StudentsMain from "./students/StudentsMain";
import CourseDetails from "./subject/CourseDetails";
import Evaluation from "./subject/Evaluation";
import Module from "./subject/Module";
import Syllabus from "./subject/Syllabus";

function SwitchCourseTab() {
  const [step, setStep] = useState(1);
  const queryClient = useQueryClient();

  const { className, classId, subjectId } = useParams();

  async function getSubject() {
    const data = await axios({
      method: "get",
      url: serverUrl + "/subjects/" + subjectId,
      data: {},
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return data.data;
  }

  const subject = useQuery(["subject", subjectId], getSubject);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <CourseDetails data={subject?.data} />
          </div>
        );

      case 2:
        return (
          <div>
            <Syllabus data={subject?.data} />
          </div>
        );

      case 3:
        return (
          <div>
            <Module data={subject?.data} />
          </div>
        );

      case 4:
        return <StudentsMain classId={classId} data={subject?.data} />;

      case 5:
        return (
          <div>
            <Evaluation subject={subject?.data} />
          </div>
        );

      default:
        return (
          <div>
            <CourseDetails data={subject?.data} />
          </div>
        );
    }
  }

  return (
    <>
      {subject.isSuccess ? (
        <div className="relative">
          <div className="sticky top-0" style={{ zIndex: 100 }}>
            {moment().isBetween(
              moment(subject?.data?.class?.transition_start_date).subtract(
                7,
                "days"
              ),
              moment(subject?.data?.class?.transition_start_date)
            ) && (
              <div className="p-2 px-10 text-white bg-gradient-to-b from-yellow-400 to-yellow-500 ">
                Transition Period will start from{" "}
                {moment(subject?.data?.class?.transition_start_date).format(
                  "dddd, MMMM Do YYYY"
                )}
                {". "}
                All the assignments, exams, modules will be drafted. Please
                evaluate all exams and Assignments ASAP
              </div>
            )}
            {moment().isBetween(
              moment(subject?.data?.class?.transition_start_date),
              moment(subject?.data?.class?.transition_end_date)
            ) && (
              <div className="p-2 px-10 text-white bg-gradient-to-b from-red-400 to-red-500 capitalize">
                Currently in Tranistion period, All The Assignments, Exams,
                Modules are moved to draft. Please Archive your Submissions
              </div>
            )}
          </div>
          <div className="p-8">
            <div className="grid grid-cols-5 bg-white rounded-lg">
              <div
                onClick={() => setStep(1)}
                className={`iskool-tab-item ${
                  step === 1 ? "bg-gray-600 text-white shadow" : ""
                }`}
              >
                <div>
                  <div className="text-xs">{className}</div>
                  <div className="text-lg">{subject.data?.name}</div>
                </div>
              </div>
              <div
                onClick={() => setStep(2)}
                className={`iskool-tab-item ${
                  step === 2 ? "bg-gray-600 text-white shadow" : ""
                }`}
              >
                <div className="text-lg">Syllabus</div>
              </div>
              <div
                onClick={() => setStep(3)}
                className={`iskool-tab-item ${
                  step === 3 ? "bg-gray-600 text-white shadow" : ""
                }`}
              >
                <div className="text-lg">Module</div>
              </div>
              <div
                onClick={() => setStep(4)}
                className={`iskool-tab-item ${
                  step === 4 ? "bg-gray-600 text-white shadow" : ""
                }`}
              >
                <div className="text-lg">Students</div>
              </div>
              {/* <div
                onClick={() => setStep(5)}
                className={`iskool-tab-item ${
                  step === 5 ? "bg-gray-600 text-white shadow" : ""
                }`}
              >
                <div className="text-lg">Evaluation</div>
              </div> */}
            </div>
            {/* ------------------------------------------------------------------------------------------- */}

            {/* -------------------------------------------------------------------------------------------- */}
            <div>
              <div>{switchTab()}</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default SwitchCourseTab;
