import { Button, Form, Input, message, Modal, Table } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { serverUrl } from "../../../../../nestserver";

const AddEditDevices = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [addTemplate, setAddTemplate] = useState(false);

  const handleSubmit = async (values) => {
    const { device_id, name } = values;
    try {
      const response = await axios.post(
        serverUrl + "/onelap-devices",
        {
          device_id,
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success("Phone number verified successfully");
        form.resetFields();
        setAddTemplate(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };
  const handleMap = async (id) => {
    try {
      const response = await axios.post(
        serverUrl + "/onelap-devices/" + id + "/map-viewers",
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      if (response.status === 200) {
        message.success("Mapped successfully");
      }
    } catch (error) {
      message.error(error?.response?.data?.error?.errors);
    }
  };
  async function fetchOneLapUser() {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/onelap-devices`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const oneLapUser = useQuery(["onelap-user"], () => fetchOneLapUser());

  const columns = [
    {
      title: "Device ID",

      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.ref_id}
        </div>
      ),
    },
    {
      title: "Name",

      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.name}
        </div>
      ),
    },
    {
      title: "Phone Number",

      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.phone ? record?.phone : "--"}
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center cursor-pointer text-blue-400"
          onClick={() => handleMap(record?._id)}
        >
          Map
        </div>
      ),
    },
  ];
  return (
    <div className="mt-8">
      <div
        style={{ borderRadius: "10px", color: "#616161" }}
        className=" bg-white drop-shadow-md p-8 w-full "
      >
        <div className="flex justify-between items-center pb-4">
          <div className="text-2xl text-orange-500 font-semibold">
            Register Devices
          </div>
          <div
            onClick={() => setAddTemplate(true)}
            className="cursor-pointer p-2 add-module flex items-center justify-center mt-2"
          >
            <div className="flex flex-col items-center space-y-3">
              <div className="text-white text-sm ">
                <div>Add new</div>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={
            oneLapUser?.isSuccess ? oneLapUser?.data?.result?.data : []
          }
          pagination={true}
          loading={oneLapUser?.isLoading}
        />
        <Modal
          width={400}
          visible={addTemplate}
          onOk={() => setAddTemplate(false)}
          onCancel={() => {
            form.resetFields();
            setAddTemplate(false);
            form.setFieldsValue({ total: "" });
          }}
          footer={null}
          forceRender
        >
          <div>
            <h3 className="text-lg font-bold pb-3"> Register Devices</h3>
            <div className="pt-4 w-4/5">
              <Form
                form={form}
                name="basic"
                onFinish={handleSubmit}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Device ID"
                  name="device_id"
                  rules={[
                    {
                      required: true,
                      message: "Device id  is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>
                {/* <Form.Item
                  label="Registered Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required",
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Phone number must be 10 digits",
                    },
                  ]}
                >
                  <Input placeholder="Enter phone number" />
                </Form.Item> */}
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>

                <div className="flex justify-center items-center py-5">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AddEditDevices;
