import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";

const ApproveTransaction = () => {
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  const dateFormat = "YYYY";
  const [form] = Form.useForm();
  const { Option } = Select;
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
  const [classId, setClassId] = useState();
  const [OpenTransactionPopup, setOpenTransactionPopup] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleSelectChange = (classId) => {
    setClassId(classId);
  };
  const columns = [
    {
      title: "Transaction Id",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.unique_id ? record?.unique_id : "--"}
        </div>
      ),
    },
    {
      title: "Name",
      key: "name",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.name}
        </div>
      ),
    },

    {
      title: "Class",
      key: "class",
      align: "center",
      render: (classData) => (
        <div
          key={classData?._id}
          style={{ color: "#111111" }}
          className="text-base font-semibold text-center"
        >
          {classData?.class?.name}
        </div>
      ),
    },
    {
      title: "Fee Type",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.feeTemplate?.type}
        </div>
      ),
    },
    {
      title: "Total",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.total_fee}
        </div>
      ),
    },
    {
      title: "Total Paid",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.total_paid}
        </div>
      ),
    },
    {
      title: "Total Remaining",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {record?.total_remaining}
        </div>
      ),
    },
    {
      title: "Payment",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.amount}
        </div>
      ),
    },
    {
      title: "Payment Mode",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.type}
        </div>
      ),
    },
    {
      title: "Payment Date",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center"
        >
          {moment(record?.date).format("DD/MM/YYYY")}
          {/* {moment(record?.date).format("DD/MM/YYYY hh:mm A")} */}
        </div>
      ),
    },
    {
      title: "User Added",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.added_by?.first_name
            ? `${record?.added_by?.first_name} ${record?.added_by?.last_name}`
            : "--"}
        </div>
      ),
    },
    {
      title: "User Approved",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.approved_by?.first_name
            ? `${record?.approved_by?.first_name} ${record?.approved_by?.last_name}`
            : "--"}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record}
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          className="text-base font-semibold text-center text-blue-500 cursor-pointer"
          onClick={() => {
            setSelectedRecord(record);
            setOpenTransactionPopup(true);
            form.setFieldsValue({
              fullName: record?.name,
              class: record?.class?.name,
              feeType: record?.feeTemplate?.type,
              totalFee: record?.total_fee,
              paid: record?.total_paid,
              remaining: record?.total_remaining,
              payment: record?.amount,
              paymentType: record?.type,
              date: moment(record?.paymentDate),
            });
          }}
        >
          View
        </div>
      ),
    },
  ];

  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };

  const classes = useQuery("classes", fetchClasses);

  const fetchClassStudents = async (selectedYear, classId) => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/admin/student-fee/transactions",
      params: {
        year: selectedYear,
        classId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const transactionApproveData = useQuery(
    ["student-fee", selectedYear, classId],
    () => fetchClassStudents(selectedYear, classId)
  );

  const onFinish = (values) => {
    if (selectedRecord?.feeTemplate?._id && selectedRecord?._id) {
      axios
        .patch(
          `${serverUrl}/admin/student-fee/${selectedRecord?.feeTemplate?._id}/transaction/${selectedRecord?._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((response) => {
          form.setFieldsValue({
            fullName: undefined,
            payment: undefined,
            paymentType: undefined,
            date: undefined,
            feeType: undefined,
          });
          queryClient.invalidateQueries(["student-fee", selectedYear, classId]);
          message.success(response?.data?.result?.message);
          setOpenTransactionPopup(false);
        })
        .catch((err) => {
          if (err?.response?.data?.error?.errors) {
            message.error(err?.response?.data?.error?.errors);
          }
        });
    }
  };

  return (
    <div className="p-4">
      <div
        style={{ borderRadius: "10px", color: "#616161" }}
        className=" bg-white drop-shadow-md w-ful mt-8 p-4 "
      >
        {" "}
        <div className="py-5 table-overflow">
          <div className="flex  space-x-3 p-4">
            <div className="flex flex-col w-310">
              <h3 className="text-base pb-3"> Class</h3>
              <Select
                showSearch
                showArrow
                placeholder="Select Class"
                className="w-full bg-gray-300"
                style={{
                  border: "6px",
                  backgroundColor: "#EBEBEB",
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectChange(value)}
              >
                {classes?.isSuccess &&
                  classes?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data?.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className="flex flex-col w-310">
              <h3 className="text-base pb-3"> Year</h3>
              <DatePicker
                picker="year"
                onChange={(date, dateString) => setSelectedYear(dateString)}
                defaultValue={moment()}
                format={dateFormat}
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={
              transactionApproveData &&
              transactionApproveData?.data?.result?.length > 0
                ? transactionApproveData?.data?.result
                : []
            }
            pagination={true}
            loading={transactionApproveData?.isLoading}
          />
        </div>
        <Modal
          width={360}
          layout="vertical"
          visible={OpenTransactionPopup}
          onOk={() => setOpenTransactionPopup(false)}
          onCancel={() => {
            setOpenTransactionPopup(false);
            form.setFieldsValue({
              fullName: undefined,
              payment: undefined,
              paymentType: undefined,
              date: undefined,
            });
          }}
          footer={null}
        >
          <div className="">
            <h3 className="text-lg font-bold pb-3">View Transaction</h3>
            <Form
              layout="vertical"
              name="basic"
              form={form}
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onFinish}
            >
              <div className="bg-white pt-2 rounded-2xl">
                <Form.Item label="Name" name="fullName">
                  <Input placeholder="Enter name" readOnly />
                </Form.Item>
                <Form.Item label="Class" name="class">
                  <Input readOnly />
                </Form.Item>
                <Form.Item label="Fee Type" name="feeType">
                  <Input readOnly className="capitalize" />
                </Form.Item>

                <Form.Item label="Total Amount" name="totalFee">
                  <Input type="number" min={0} readOnly />
                </Form.Item>
                <Form.Item label="Total Paid" name="paid">
                  <Input type="number" min={0} readOnly />
                </Form.Item>
                <Form.Item label="Total Remaining" name="remaining">
                  <Input type="number" min={0} readOnly />
                </Form.Item>
                <Form.Item label="Payment" name="payment">
                  <Input
                    type="number"
                    min={0}
                    placeholder="Enter amount"
                    readOnly
                  />
                </Form.Item>
                <Form.Item label="Type" name="paymentType">
                  <Input readOnly className="capitalize" />
                </Form.Item>
                <Form.Item label="Date" name="date">
                  <DatePicker
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    disabled
                  />
                </Form.Item>
                <div className="flex justify-center items-center py-5">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                      disabled={
                        !user?.admin?.can_approve_transaction ||
                        selectedRecord?.status === "approved"
                      }
                    >
                      {user?.admin?.can_approve_transaction &&
                      selectedRecord?.status === "pending"
                        ? "Approve"
                        : selectedRecord?.status === "approved"
                        ? "Approved"
                        : "Pending"}
                    </Button>
                    <Button
                      size="large"
                      type="dark"
                      onClick={() => {
                        setOpenTransactionPopup(false);
                        form.setFieldsValue({
                          fullName: undefined,
                          payment: undefined,
                          paymentType: undefined,
                          date: undefined,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ApproveTransaction;
