import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";
import StudentFeDetails from "./StudentFeeDetails";
import ViewTransactionHistory from "./ViewTransactionHistory";
const ViewStudentFee = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const { Option } = Select;
  const dateFormat = "YYYY";
  let isViewStudentFee = true;
  const [OpenTransactionPopup, setOpenTransactionPopup] = useState(false);
  const [OpenApplyTemplate, setOpenApplyTemplate] = useState(false);
  const [openTransactionHistory, setOpenTransactionHistory] = useState(false);
  const [classId, setClassId] = useState("");
  const [studentId, setStudentId] = useState("");
  const [studentFee, setStudentFee] = useState();
  const [transactionHistory, setTransactionHistory] = useState(null);
  const [studentFeeId, setStudentFeeId] = useState();
  const [studentData, setStudentData] = useState();
  const [studentDataShow, setStudentDataShow] = useState();
  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }
  const fetchStudentFee = async () => {
    await axios({
      method: "get",
      url:
        serverUrl +
        "/admin/fee-template/class/" +
        classId +
        "/student/" +
        studentId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((response) => {
        if (response?.data?.result && response?.data?.result.length > 0) {
          setStudentDataShow(response?.data?.result);
          const firstData = response?.data?.result[0];
          setStudentData(firstData);
          const transportation = response?.data?.result?.find(
            (data) => data?.fee_template?.type === "transportation"
          );
          const accommodation = response?.data?.result?.find(
            (data) => data?.fee_template?.type === "accommodation"
          );

          if (transportation) {
            form.setFieldsValue({
              transportation: transportation?.fee_template?._id,
            });
          }
          if (accommodation) {
            form.setFieldsValue({
              accommodation: accommodation?.fee_template?._id,
            });
          }
          form.setFieldsValue({
            name: `${firstData?.student?.first_name} ${firstData?.student?.last_name}`,
            studentFullName: `${firstData?.student?.first_name} ${firstData?.student?.last_name}`,
            studentId: firstData?.student?.unique_id,
            studentStudentId: firstData?.student?.unique_id,
            class: firstData?.class?.name,
            parent: `${firstData?.student?.parent?.first_name} ${firstData?.student?.parent?.last_name}`,
            phone: firstData?.student?.parent?.phone,
          });
        }

        setStudentFee(response?.data?.result);
      })
      .catch((error) => {
        console.error("Error fetching student fee:", error);
      });
  };

  useEffect(() => {
    if (classId && studentId) {
      fetchStudentFee();
    }
  }, [classId, studentId]);
  const onFinish = (values) => {
    const transactionPayload = {
      name: values?.fullName,
      type: values?.paymentType,
      amount: values?.payment,
      date: moment(values?.date).format("YYYY-MM-DD"),
    };
    if (studentFeeId) {
      axios
        .post(
          serverUrl + "/admin/student-fee/" + studentFeeId + "/transaction",
          transactionPayload,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((response) => {
          form.setFieldsValue({
            fullName: undefined,
            payment: undefined,
            paymentType: undefined,
            date: undefined,
            feeType: undefined,
          });
          message.success(response?.data?.result?.message);
          setOpenTransactionPopup(false);
          fetchStudentFeeHistory();
        })
        .catch((err) => {
          if (err?.response?.data?.error?.errors) {
            message.error(err?.response?.data?.error?.errors);
          }
        });
    }
  };
  const onApplyTemplate = (values) => {
    const isTransportation = studentDataShow?.some(
      (data) => data?.fee_template?.type === "transportation"
    );
    const isAccommodation = studentDataShow?.some(
      (data) => data?.fee_template?.type === "accommodation"
    );
    const payload = {
      ...(values.transportation && !isTransportation
        ? { transportation_id: values.transportation }
        : {}),
      ...(values.accommodation && !isAccommodation
        ? { accommodation_id: values.accommodation }
        : {}),
    };

    if (studentId) {
      axios
        .patch(
          `${serverUrl}/admin/fee-template/apply/student/${studentId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "jwt-token"
              )}`,
            },
          }
        )
        .then((response) => {
          message.success(response?.data?.result?.message);
          fetchStudentFee();
          setOpenApplyTemplate(false);
        })
        .catch((err) => {
          if (err?.response?.data?.error?.errors) {
            message.error(err?.response?.data?.error?.errors);
          }
        });
    }
  };
  const fetchStudentFeeHistory = async () => {
    await axios({
      method: "get",
      url:
        serverUrl + "/admin/student-fee/" + studentId + "/transaction-history",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    })
      .then((response) => {
        setTransactionHistory(response?.data?.result);
      })
      .catch((err) => {
        // if (err?.response?.data?.error?.errors) {
        //   message.error(err?.response?.data?.error?.errors);
        // }
      });
  };

  const handleFeeTypeChange = (feeTemplateId) => {
    const feeTypeData = studentFee?.find(
      (data) => data?.fee_template?._id === feeTemplateId
    );
    setStudentFeeId(feeTypeData ? feeTypeData?._id : "");
    form.setFieldsValue({
      totalFee: feeTypeData?.total || 0,
      paid: feeTypeData?.paid || 0,
      remaining: feeTypeData?.remaining || 0,
      user: `${user?.admin?.first_name} ${user?.admin?.last_name}` || "",
    });
  };

  useEffect(() => {
    if (studentFee) {
      fetchStudentFeeHistory();
    }
  }, [studentFee]);

  async function fetchTemplateData(
    type,
    selectedYear,
    classId,
    routeId,
    destinationId
  ) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/admin/fee-template/list`,
      params: {
        type,
        year: selectedYear,
        classId: classId,
        routeId,
        destinationId,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res?.data?.result;
  }

  // React Query hooks for fetching data
  const routerId = studentData?.student?.user?.route_id;
  const desitinationId = studentData?.student?.user?.route_entry_point_id;

  const {
    data: templateData,
    isLoading: isLoadingTution,
    error: errorTution,
  } = useQuery(["fee-template", "accommodation", selectedYear, classId], () =>
    fetchTemplateData("accommodation", selectedYear, classId)
  );
  const { data: transportTemplateData } = useQuery(
    [
      "fee-template",
      "transportation",
      selectedYear,
      classId,
      routerId,
      desitinationId,
    ],
    () =>
      fetchTemplateData(
        "transportation",
        selectedYear,
        null,
        routerId,
        desitinationId
      )
  );

  const isTemplate = studentFee?.some(
    (data) => data?.fee_template?.type === "accommodation"
  );

  useEffect(() => {
    const urlParts = window.location.pathname.split("/");
    const classIdIndex = urlParts.indexOf("class");
    const studentIdIndex = urlParts.indexOf("student");

    if (classIdIndex !== -1 && studentIdIndex !== -1) {
      setClassId(urlParts[classIdIndex + 1]);
      setStudentId(urlParts[studentIdIndex + 1]);
    }
  }, []);

  const hasNotBothAccommodationAndTransportation = !(
    studentDataShow?.some(
      (data) => data?.fee_template?.type === "accommodation"
    ) &&
    studentDataShow?.some(
      (data) => data?.fee_template?.type === "transportation"
    )
  );

  console.log(
    "hasNotBothAccommodationAndTransportation",
    hasNotBothAccommodationAndTransportation
  );

  return (
    <div className="student-form-fee">
      <div className="p-8">
        <div className="bg-white p-10 rounded-2xl">
          <div className="p-4 text-right">
            <Button size="large" onClick={() => history.goBack()}>
              Back
            </Button>
          </div>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div className="form-grid">
              <Form.Item label="Name" name="name">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Student Id" name="studentId">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Class" name="class">
                <Input readOnly />
              </Form.Item>
            </div>
            <div className="form-grid">
              <Form.Item label="Parent" name="parent">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Phone" name="phone">
                <Input readOnly />
              </Form.Item>
            </div>
          </Form>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <div>
              <div className="flex justify-center gap-8">
                <div className="bg-white pt-4 rounded-2xl flex justify-between gap-4">
                  <Button
                    onClick={() => {
                      setOpenTransactionPopup(true);
                    }}
                  >
                    Add Transaction
                  </Button>
                </div>
                <div className="bg-white pt-4 rounded-2xl flex justify-between gap-4 bo">
                  <Button
                    onClick={() => {
                      setOpenApplyTemplate(true);

                      form.setFieldsValue({
                        templateYear: moment(),
                      });
                    }}
                  >
                    Apply Template
                  </Button>
                </div>
                <div className="flex justify-center pt-4 items-center">
                  <Button
                    class
                    onClick={() => {
                      setOpenTransactionHistory(true);
                      fetchStudentFeeHistory();
                    }}
                  >
                    View History
                  </Button>
                </div>
              </div>

              <StudentFeDetails
                feeResponse={studentFee}
                isViewStudentFee={isViewStudentFee}
                fetchStudentFee={fetchStudentFee}
                classId={classId}
                studentId={studentId}
              />
            </div>
          </Form>
        </div>
      </div>

      <Modal
        width={360}
        layout="vertical"
        visible={OpenTransactionPopup}
        onOk={() => setOpenTransactionPopup(false)}
        onCancel={() => {
          setOpenTransactionPopup(false);
          form.setFieldsValue({
            fullName: undefined,
            payment: undefined,
            paymentType: undefined,
            date: undefined,
          });
        }}
        footer={null}
      >
        <div className="">
          <h3 className="text-lg font-bold pb-3">Add Transaction</h3>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <div className="bg-white pt-2 rounded-2xl">
              <Form.Item
                label="Name"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
              <Form.Item label="Class" name="class">
                <Input readOnly />
              </Form.Item>
              <Form.Item
                label="Fee Type"
                name="feeType"
                rules={[
                  {
                    required: true,
                    message: "Fee Type is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  showArrow
                  placeholder="Select fee type"
                  className="w-full bg-gray-300 capitalize"
                  style={{
                    border: "6px",
                    backgroundColor: "#EBEBEB",
                  }}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={handleFeeTypeChange}
                >
                  {studentFee
                    ?.filter((data) => data.fee_template)
                    .map((data) => {
                      return (
                        <Option
                          key={data?.fee_template?._id}
                          value={data?.fee_template?._id}
                        >
                          {data?.fee_template?.type}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item label="Total Amount" name="totalFee">
                <Input type="number" min={0} readOnly />
              </Form.Item>
              <Form.Item label="Total Paid" name="paid">
                <Input type="number" min={0} readOnly />
              </Form.Item>
              <Form.Item label="Total Remaining" name="remaining">
                <Input type="number" min={0} readOnly />
              </Form.Item>
              <Form.Item
                label="Payment"
                name="payment"
                rules={[
                  {
                    required: true,
                    message: "Payment is required",
                  },
                ]}
              >
                <Input type="number" min={0} placeholder="Enter amount" />
              </Form.Item>
              <Form.Item
                label="Type"
                name="paymentType"
                rules={[
                  {
                    required: true,
                    message: "Payment Type is required",
                  },
                ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placeholder="Select mode"
                >
                  <Select.Option value="cash">Cash</Select.Option>
                  <Select.Option value="card">Card</Select.Option>
                  <Select.Option value="online">Online</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Date" name="date">
                <DatePicker
                  format="DD-MM-YYYY"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  className="w-full"
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item label="User" name="user">
                <Input readOnly />
              </Form.Item>
              <div className="flex justify-center items-center py-5">
                <div className="inline-flex items-center space-x-2">
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="text-white"
                  >
                    Complete
                  </Button>
                  <Button
                    size="large"
                    type="dark"
                    onClick={() => {
                      setOpenTransactionPopup(false);
                      form.setFieldsValue({
                        fullName: undefined,
                        payment: undefined,
                        paymentType: undefined,
                        date: undefined,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      {/* ****************************** */}
      <Modal
        width={360}
        layout="vertical"
        visible={OpenApplyTemplate}
        onOk={() => setOpenApplyTemplate(false)}
        onCancel={() => {
          setOpenApplyTemplate(false);
        }}
        footer={null}
      >
        <div className="">
          <h3 className="text-lg font-bold pb-3">Apply Template</h3>
          <Form
            layout="vertical"
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onApplyTemplate}
          >
            <div className="bg-white pt-2 rounded-2xl">
              <Form.Item
                label="Name"
                name="studentFullName"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input placeholder="Enter name" readOnly />
              </Form.Item>
              <Form.Item
                label="StudentId"
                name="studentStudentId"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input placeholder="Enter name" readOnly />
              </Form.Item>
              <Form.Item label="Class" name="class">
                <Input readOnly />
              </Form.Item>
              <Form.Item
                label="Year"
                name="templateYear"
                rules={[
                  {
                    required: true,
                    message: "Please select a year!",
                  },
                ]}
              >
                <DatePicker
                  picker="year"
                  onChange={(date, dateString) => setSelectedYear(dateString)}
                  defaultValue={moment()}
                  format={dateFormat}
                  className="w-full"
                  disabled={!hasNotBothAccommodationAndTransportation}
                />
              </Form.Item>
              {studentData?.student?.user?.is_accommodation_required && (
                <Form.Item label="Accommodation" name="accommodation">
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select accommodation"
                    className="w-full bg-gray-300 capitalize"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={handleFeeTypeChange}
                    disabled={studentDataShow?.some(
                      (data) => data?.fee_template?.type === "accommodation"
                    )}
                  >
                    {templateData
                      ?.filter((data) => data.type === "accommodation")
                      .map((data) => {
                        return (
                          <Option key={data?._id} value={data?._id}>
                            {data?.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
              {studentData?.student?.user?.is_transportation_required && (
                <Form.Item label="Transportation" name="transportation">
                  <Select
                    showSearch
                    showArrow
                    placeholder="Select transportation"
                    className="w-full bg-gray-300 capitalize"
                    style={{
                      border: "6px",
                      backgroundColor: "#EBEBEB",
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={handleFeeTypeChange}
                    disabled={studentDataShow?.some(
                      (data) => data?.fee_template?.type === "transportation"
                    )}
                  >
                    {transportTemplateData
                      ?.filter((data) => data.type === "transportation")
                      .map((data) => {
                        return (
                          <Option key={data?._id} value={data?._id}>
                            {data?.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
              {hasNotBothAccommodationAndTransportation && (
                <div className="flex justify-center items-center py-5">
                  <div className="inline-flex items-center space-x-2">
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      className="text-white"
                      disabled={
                        studentDataShow?.some(
                          (data) => data?.fee_template?.type === "accommodation"
                        ) &&
                        studentDataShow?.some(
                          (data) =>
                            data?.fee_template?.type === "transportation"
                        )
                      }
                    >
                      Apply
                    </Button>
                    <Button
                      size="large"
                      type="dark"
                      onClick={() => {
                        setOpenApplyTemplate(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        width={900}
        layout="vertical"
        visible={openTransactionHistory}
        onOk={() => setOpenTransactionHistory(false)}
        onCancel={() => {
          setOpenTransactionHistory(false);
        }}
        footer={null}
      >
        <ViewTransactionHistory
          transactionHistory={transactionHistory}
          studentId={studentId}
        />
      </Modal>
    </div>
  );
};

export default ViewStudentFee;
