import { Input, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { serverUrl } from "../../../../../nestserver";
import { selectUser } from "../../../../../redux/slices/auth.slice";
import ConductCertificate from "./component/ConductCertificate";
import StudyCertificate from "./component/StudyCertificate";
import TransferCertificate from "./component/TransferCertificate";

const ManageFormTemplate = () => {
  const { Option } = Select;
  const user = useSelector(selectUser);
  const [formType, setFormType] = useState();
  const [classId, setClassId] = useState();
  const [studentId, setStudentId] = useState();
  const [studentDetails, setStudentDetails] = useState({ unique_id: null });
  const [templateId, setTemplateId] = useState();

  const handleSelectChange = (type) => {
    setFormType(type);
  };
  const handleSelectClassChange = (classId) => {
    if (classId) {
      setClassId(classId);
      setStudentId(null);
      setStudentDetails({ unique_id: null });
    }
  };
  async function fetchClasses() {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const classes = useQuery("classes", fetchClasses);

  const fetchStudentData = async () => {
    const res = await axios.get(
      serverUrl + "/attendance/students/class/" + classId,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
      }
    );

    return res.data;
  };
  const classDetails = useQuery(["class", classId], fetchStudentData, {
    enabled: !!classId,
  });

  const handleSelectStudentChange = (value) => {
    setStudentId(value);
    if (value) {
      const selectedStudent = classDetails?.data?.data?.find(
        (data) => data._id === value
      );
      if (selectedStudent) {
        setStudentDetails({ unique_id: selectedStudent?.unique_id });
      }
    } else {
      setStudentDetails({ unique_id: null });
    }
  };

  async function fetchSchoolFormTemplate(id) {
    const res = await axios({
      method: "get",
      url: serverUrl + "/form-template?school=" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const getSchoolTemplate = useQuery(
    ["form-template", user?.school?._id],
    () => fetchSchoolFormTemplate(user?.school?._id), // Pass the function directly
    {
      enabled: !!user?.school?._id,
    }
  );
  async function fetchStudentFormTemplate(studentId, templateId) {
    const res = await axios({
      method: "get",
      url:
        serverUrl +
        "/student-form?student=" +
        studentId +
        "&template=" +
        templateId,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }

  const getStudentTemplate = useQuery(
    ["student-form", studentId, templateId],
    () => fetchStudentFormTemplate(studentId, templateId), // Pass the function directly
    {
      enabled: !!(studentId && templateId),
    }
  );
  async function fetchFormType(id) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/form-template/?school=${id}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const formTypeList = useQuery(
    ["subjects", user?.school?._id],
    () => user?.school?._id && fetchFormType(user?.school?._id),
    {
      enabled: !!user?.school?._id,
    }
  );

  useEffect(() => {
    if (getSchoolTemplate?.data?.result?.data) {
      const schooltemplateId = getSchoolTemplate?.data?.result?.data.find(
        (schoolTemplate) => schoolTemplate?.form_type === formType
      );
      setTemplateId(schooltemplateId?._id);
    }
  }, [formType]);

  return (
    <div>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md p-8 w-full "
        >
          <div className="text-2xl text-orange-500 font-semibold pb-8">
            Manage Form Template
          </div>
          <div className="flex">
            <div className="flex gap-4 items-center" style={{ width: "280px" }}>
              <h3 className="text-base font-semibold">Class</h3>
              <Select
                showSearch
                showArrow
                style={{ width: 200 }}
                placeholder="Select Class"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectClassChange(value)}
                // allowClear
              >
                {classes?.isSuccess &&
                  classes?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {data.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className="flex gap-4 w-310 items-center">
              <h3
                className="text-base font-semibold"
                style={{ width: "100px" }}
              >
                Form Type
              </h3>
              <Select
                showSearch
                showArrow
                style={{ width: "250px" }}
                placeholder="Select Form Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectChange(value)}
                allowClear
                className="capitalize"
              >
                {formTypeList?.isSuccess &&
                  formTypeList?.data?.result?.data?.map((data) => {
                    const originalString = `${data?.form_type}`;
                    const newString = originalString.replace(/_/g, " ");
                    return (
                      <Option key={data._id} value={data.form_type}>
                        {newString}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className="flex pt-4 items-center">
            <div className="flex gap-4 items-center" style={{ width: "280px" }}>
              <h3 className="text-base font-semibold">Name</h3>
              <Select
                showSearch
                showArrow
                style={{ width: 200 }}
                placeholder="Select name"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelectStudentChange(value)}
                allowClear
                className="capitalize"
                value={studentId}
              >
                {classDetails?.isSuccess &&
                  classDetails?.data?.data?.map((data) => {
                    return (
                      <Option key={data._id} value={data._id}>
                        {`${data.first_name} ${data.last_name}`}
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className="flex gap-4 items-center">
              <h3
                className="text-base font-semibold  flex-1"
                style={{ width: "120px" }}
              >
                Student Id
              </h3>
              <Input
                readOnly
                value={studentDetails?.unique_id}
                placeholder="Student Id"
              />
            </div>
          </div>
          {formType === "transfer_certificate" && (
            <TransferCertificate
              getStudentTemplate={getStudentTemplate?.data?.result?.data}
              formType="transfer_certificate"
              studentId={studentId}
              templateId={templateId}
            />
          )}
          {formType === "study_certificate" && (
            <StudyCertificate
              getStudentTemplate={getStudentTemplate?.data?.result?.data}
              formType="study_certificate"
              studentId={studentId}
              templateId={templateId}
            />
          )}
          {formType === "conduct_certificate" && (
            <ConductCertificate
              getStudentTemplate={getStudentTemplate?.data?.result?.data}
              formType="conduct_certificate"
              studentId={studentId}
              templateId={templateId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageFormTemplate;
