import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useState } from "react";
import { serverUrl } from "../../../../../nestserver";

const ViewTransactionHistory = ({ transactionHistory, studentId }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchInput, setSearchInput] = useState(null);

  const handleDownload = async (e, transactionId) => {
    e.preventDefault();
    axios({
      url:
        serverUrl +
        "/student-fee/" +
        studentId +
        "/transaction/" +
        transactionId +
        "/download",
      method: "GET",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        responseType: "blob",
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    }).then((response) => {
      saveAs(response.data, "transaction history");
    });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={setSearchInput}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="flex items-center"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      align: "center",
      ...getColumnSearchProps("name"),
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base font-semibold text-center capitalize"
        >
          {record?.name}
        </div>
      ),
      onFilter: (value, record) =>
        record?.name?.toLowerCase().includes(value.toLowerCase()),
    },

    {
      title: "Paid Date",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className="text-base  text-center"
        >
          {moment(record?.fee_details?.last_paid).format("DD-MMM-YYYY")}
        </div>
      ),
    },
    {
      title: "Total Fee",
      dataIndex: "",
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className="text-base text-center"
        >
          {record?.total_fee}
        </div>
      ),
    },
    {
      title: "Total Paid",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div style={{ color: "#616161" }} className=" text-base">
          {record?.total_paid}
        </div>
      ),
    },
    {
      title: "Current Payment",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div style={{ color: "#616161" }} className=" text-base">
          {record?.amount}
        </div>
      ),
    },
    {
      title: "Total Remaining",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div style={{ color: "#616161" }} className=" text-base">
          {record?.total_remaining}
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: [],
      key: "",
      render: (record) => (
        <div style={{ color: "#616161" }} className=" text-base capitalize">
          {record?.type}
        </div>
      ),
    },

    {
      title: "Due Date",
      dataIndex: [],
      key: "",
      align: "center",
      render: (record) => (
        <div
          key={record?._id}
          style={{ color: "#111111" }}
          className=" text-base text-center capitalize"
        >
          {moment(record?.due_date).format("DD-MMM-YYYY")}
        </div>
      ),
    },
    {
      title: "Download",
      dataIndex: [],
      key: "createdAt",
      render: (record) => (
        <div
          className=" text-base text-center cursor-pointer"
          onClick={(e) => handleDownload(e, record?._id)}
        >
          <DownloadOutlined color="primary" />
        </div>
      ),
    },
  ];
    // const transactionHistory = transactionData.transactions || [];
  return (
    <>
      <div className="">
        <h3 className="text-lg font-bold pb-3">Transaction History</h3>
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md w-full "
        >
          <div className="py-5 table-overflow">
            {transactionHistory &&
              transactionHistory?.length > 0 &&
              transactionHistory?.map((transactionHistory, index) => (
                <>
                  <div style={{ marginBottom: "70px" }}>
                    <h3 className="text-base font-semibold text-center mb-4 capitalize">
                      {transactionHistory?.feeTemplate?.type}
                    </h3>
                    <Table
                      key={index}
                      columns={columns}
                      dataSource={
                        transactionHistory &&
                        transactionHistory?.transactions?.length > 0
                          ? transactionHistory?.transactions?.sort((a, b) =>
                              moment(a?.createdAt).isBefore(b?.createdAt)
                                ? 1
                                : -1
                            )
                          : []
                      }
                      pagination={false}
                      loading={transactionHistory?.isLoading}
                    />
                  </div>
                </>
              ))}
            {/* <Table
              columns={columns}
              dataSource={
                transactionHistory && transactionHistory.length > 0
                  ? transactionHistory?.sort((a, b) =>
                      moment(a?.createdAt).isBefore(b?.createdAt) ? 1 : -1
                    )
                  : []
              }
              pagination={true}
              loading={transactionHistory?.isLoading}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTransactionHistory;
