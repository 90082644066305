import { Button, DatePicker, Form, Input, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { defaultStatus, feeType } from "../../../../../helper/options";
import { serverUrl } from "../../../../../nestserver";
import FeeTable from "./FeeTable";

const AddEditFeeTemplate = () => {
  let { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const history = useHistory();
  const [form] = Form.useForm();
  let isFeeTemplate = true;
  const { Option } = Select;
  const [otherFields, setOtherFields] = useState([{}]);
  const [feeComponents, setFeeComponents] = useState();
  const [feeResponse, setFeeResponse] = useState();
  const [destinationId, setDestinationId] = useState();
  const [selectedYearr, setSelectedYearr] = useState(null);
  const [data, setData] = useState({
    tution: "",
  });

  const disable = feeResponse?.status === "applied";

  const addOtherField = () => {
    setOtherFields([
      ...otherFields,
      { value: "", label: "", index: otherFields.length },
    ]);

    form.setFieldsValue({
      [`otherField_${otherFields.length}`]: "",
    });
  };

  const removeOtherField = (index) => {
    const newFields = [...otherFields];
    newFields.splice(index, 1);
    setOtherFields(newFields);
  };

  const handleFeeData = (paramName, value) => {
    setData({ ...data, [paramName]: value });
  };
  const fetchClasses = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/classes",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const classes = useQuery("classes", fetchClasses);

  const handleOtherFieldChange = (value, index, field) => {
    const newFields = [...otherFields];
    newFields[index] = {
      ...newFields[index],
      [field]: value,
    };
    setOtherFields(newFields);
    if (field === "value") {
      form.setFieldsValue({
        [`otherField_${index}`]: value,
      });
    }
  };
  const fetchFeeTemplate = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/admin/fee-template/" + id,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const applyFeeTemplate = async () => {
    try {
      const response = await axios.patch(
        `${serverUrl}/admin/fee-template/${id}/apply/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
          },
        }
      );
      const feeResponse = await fetchFeeTemplate();
      setFeeResponse(feeResponse?.result);
      message.success(response?.data?.result?.message);
    } catch (err) {
      if (err?.response?.data?.error?.errors) {
        message.error(err?.response?.data?.error?.errors);
      } else {
        message.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  function disabledDatee(current) {
    return current && current < moment().startOf("day");
  }
  function disabledDate(current) {
    if (selectedYearr) {
      return current && current < moment(`${selectedYearr}-01-01`);
    }
    return current && current < moment().startOf("day");
  }

  const fetchRoute = async () => {
    const res = await axios({
      method: "get",
      url: serverUrl + "/routes/",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });
    return res.data;
  };
  const route = useQuery("route", fetchRoute);
  async function fetchSubjects(destination) {
    const res = await axios({
      method: "get",
      url: `${serverUrl}/routes/${destination}`,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
      },
    });

    return res.data;
  }
  const designationData = useQuery(
    ["subjects", destinationId],
    () => destinationId && fetchSubjects(destinationId),
    {
      enabled: !!destinationId,
    }
  );
  useEffect(() => {
    if (id) {
      fetchFeeTemplate().then((response) => {
        setFeeResponse(response?.result);
        form.setFieldsValue({
          title: response?.result?.title,
          total: response?.result?.total,
          year: moment(response?.result?.year),
          due_date: moment(response?.result?.due_date),
          installment_count: response?.result?.installment_count,
          fee_components: response?.result?.fee_components,
        });
        if (type !== "transportation") {
          form.setFieldsValue({
            class: response?.result?.class?._id,
          });
        }
        if (response?.result?.destination_id) {
          form.setFieldsValue({
            destination: response?.result?.destination_id?._id,
          });
        }
        if (response?.result?.route_id) {
          setDestinationId(response?.result?.route_id?._id);
          form.setFieldsValue({
            route: response?.result?.route_id?._id,
          });
        }
        const updatedData = response?.result.fee_components.reduce(
          (acc, curr, index) => {
            if (curr.name === feeType.tution) {
              acc.tution = curr.amount;
            } else if (curr.name === feeType.other) {
              acc.other = curr.amount;
            } else {
              acc.otherFields.push({
                label: curr.name,
                value: curr.amount,
                index,
              });
            }
            return acc;
          },
          { tution: "", other: "", otherFields: [] }
        );
        updatedData.otherFields.forEach((field, index) => {
          form.setFieldsValue({
            [`otherField_${index}`]: field.value,
          });
        });

        setOtherFields([...updatedData.otherFields]);
        form.setFieldsValue({
          tution: updatedData.tution,
          other: updatedData.other,
        });
        setData({
          tution: updatedData.tution,
          other: updatedData.other,
        });
        setFeeComponents(response?.result?.fee_components);
      });
    }
  }, [id]);
  const editFeeTemplate = async (values) => {
    try {
      const feeComponentsData = [
        ...(data.tution && Number(data.tution) > 0 && feeType.tution
          ? [{ name: feeType.tution, amount: Number(data.tution) }]
          : []),
        ...otherFields
          .filter((field) => field.label && Number(field.value) > 0)
          .map((field) => ({
            name: field.label,
            amount: Number(field.value),
          })),
      ];

      const payload = {
        type: type,
        class: values.class,
        title: values.title,
        year: moment(values.year).format("YYYY"),
        due_date: moment(values.due_date).format("YYYY-MM-DD"),
        installment_count: values.installment_count,
        fee_components: feeComponentsData,
        total: feeComponentsData.reduce((sum, item) => sum + item.amount, 0),
      };
      if (type === "transportation") {
        payload.destination_id = values.destination;
        payload.route_id = values.route;
      }

      const response = await axios({
        method: "put",
        url: `${serverUrl}/admin/fee-template/${id}`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt-token")}`,
        },
        data: payload,
      });

      if (response.data) {
        message.success("Fee template updated successfully");
        history.goBack();
      }
    } catch (err) {
      if (err?.response?.data?.error?.errors) {
        message.error(err?.response?.data?.error?.errors);
      } else {
        message.error("Failed to update fee template");
      }
      console.error("Error updating fee template:", err);
    }
  };

  return (
    <div>
      <div className="p-4 mt-8 pt-0">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md w-full p-4 "
        >
          {feeResponse?.status === defaultStatus?.pending ? (
            <div className="text-sm font-semibold">
              <span className="text-base font-semibold">CAUTION:-</span>
              Fee Template once applied for a class cannot be modified/deleted
              through-out the Academic year. Please check the fee structure and
              No terms for fee collection and proceed" Click on OK opens the
              pop-up again (Secondary Pop-up) with Apply Template and Cancel
            </div>
          ) : (
            <div className="text-sm font-semibold">
              **<span className="text-base font-semibold">NOTE:-</span>The
              Applied templates are NOT editable/deletable for the complete year
              - If you wish to modify the fee for individual student following
              the path below" Menu - Manage Fee -Classes - Select class - View
              Individual Student{" "}
            </div>
          )}
          <div>
            <div className="p-4 text-right">
              <Button size="large" onClick={() => history.goBack()}>
                Back
              </Button>
            </div>
            <div className="p-4" style={{ width: "60%" }}>
              <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={editFeeTemplate}
              >
                <Form.Item
                  label="Title"
                  name="title"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                  ]}
                >
                  <Input readOnly={disable} />
                </Form.Item>

                {type !== "transportation" && (
                  <Form.Item
                    label="Class"
                    name="class"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please select a class",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      showArrow
                      placeholder="Select Class"
                      className="w-full bg-gray-300"
                      style={{
                        border: "6px",
                        backgroundColor: "#EBEBEB",
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      disabled={disable}
                    >
                      {classes?.isSuccess &&
                        classes?.data?.map((data) => {
                          return (
                            <Option key={data?._id} value={data?._id}>
                              {data?.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  name="tution"
                  label="Fee"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: " Fee is required",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={0}
                    onChange={(e) => {
                      handleFeeData("tution", e.target.value);
                    }}
                    readOnly={disable}
                    // readOnly
                  />
                </Form.Item>
                {otherFields &&
                  otherFields.length > 0 &&
                  otherFields?.map((field, index) => (
                    <div key={index} className=" space-x-5 mb-2">
                      <h6 key={index} className="mb-8px capitalize">
                        {field?.label}
                      </h6>
                      <div className="flex ml-0px space-x-4 other-feild">
                        <Form.Item
                          name={`otherField_${index}`}
                          label={
                            <Input
                              value={field.label}
                              onChange={(e) => {
                                const value = e.target.value;
                                const capitalizedValue =
                                  value.charAt(0).toUpperCase() +
                                  value.slice(1);
                                handleOtherFieldChange(
                                  capitalizedValue,
                                  index,
                                  "label"
                                );
                              }}
                              readOnly={disable}
                              placeholder="Enter title"
                              className="w-24 mr-2 flex"
                            />
                          }
                          className="w-30"
                        >
                          <Input
                            value={field.value}
                            onChange={(e) =>
                              handleOtherFieldChange(
                                e.target.value,
                                index,
                                "value"
                              )
                            }
                            readOnly={disable}
                            className="mr-2"
                            placeholder="Enter amount"
                            type="number"
                            min={0}
                          />
                        </Form.Item>
                        {feeResponse?.status !== "applied" && (
                          <Button
                            type="danger"
                            onClick={() => removeOtherField(index)}
                            className="ml-2"
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                {feeResponse?.status !== "applied" && (
                  <div className="pb-4">
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={addOtherField}
                      className="mb-2"
                    >
                      Add
                    </Button>
                  </div>
                )}
                {type === "transportation" && (
                  <>
                    <Form.Item
                      label="Route"
                      name="route"
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "Please select a route",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select route"
                        className="w-full bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(value) => {
                          form.setFieldsValue({ destination: null });
                          setDestinationId(value);
                        }}
                        optionFilterProp="children"
                        disabled={disable}
                      >
                        {route?.isSuccess &&
                          route?.data?.result?.data?.map((data) => {
                            return (
                              <Option key={data._id} value={data._id}>
                                {data.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Destination"
                      name="destination"
                      className="w-full"
                      rules={[
                        {
                          required: true,
                          message: "Please select destination",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        showArrow
                        placeholder="Select destination"
                        className="w-full bg-gray-300"
                        style={{
                          border: "6px",
                          backgroundColor: "#EBEBEB",
                        }}
                        optionFilterProp="children"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabled={disable}
                      >
                        {designationData?.isSuccess &&
                          designationData?.data?.result?.data?.route_map?.map(
                            (data) => {
                              return (
                                <Option key={data._id} value={data._id}>
                                  {data.name}
                                </Option>
                              );
                            }
                          )}
                      </Select>
                    </Form.Item>
                  </>
                )}
                <Form.Item name="total" label="Total Amount" className="w-30">
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="year"
                  label="Year"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Year is required",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    onChange={(value) => {
                      form.setFieldsValue({ due_date: null });
                      setSelectedYearr(value ? moment(value).year() : null);
                    }}
                    disabledDate={disabledDatee}
                    disabled={disable}
                  />
                </Form.Item>
                <Form.Item name="due_date" label="Due Date" className="w-30">
                  <DatePicker
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    className="w-full"
                    disabledDate={disabledDate}
                    rules={[
                      {
                        required: true,
                        message: "Due date is required",
                      },
                    ]}
                    disabled={disable}
                  />
                </Form.Item>
                <Form.Item
                  name="installment_count"
                  label="Number of terms (Max 10)"
                  className="w-30"
                  rules={[
                    {
                      required: true,
                      message: "Number of terms is required",
                    },
                    {
                      validator: (_, value) => {
                        if (value && (value < 1 || value > 10)) {
                          return Promise.reject(
                            "Number of terms should be between 1 and 10"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Enter number of terms"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    readOnly={disable}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
                {feeResponse?.status !== "applied" && (
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save Changes
                    </Button>
                  </Form.Item>
                )}
              </Form>
              {feeResponse &&
                feeResponse?.type !== "accommodation" &&
                feeResponse?.status === defaultStatus?.pending && (
                  <div className="flex justify-center items-center py-5">
                    <div className="inline-flex items-center space-x-2">
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="text-white"
                        onClick={applyFeeTemplate}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                )}
            </div>
            {type !== "transportation" && (
              <FeeTable
                feeResponse={feeResponse}
                setFeeResponse={setFeeResponse}
                isFeeTemplate={isFeeTemplate}
                fetchFeeTemplate={fetchFeeTemplate}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditFeeTemplate;
