import React from "react";
import StudentAssignmentsTable from "./StudentAssignment";
import StudentExamTable from "./StudentExamTable";

function StudentEvaluation({ subjectDetails, isExam, isAssignment }) {
  return (
    <>
      <div className="mt-8">
        <div
          style={{ borderRadius: "10px", color: "#616161" }}
          className=" bg-white drop-shadow-md w-full "
        >
          {isAssignment && (
            <>
              <div className="h-16 ">
                <div
                  style={{ backgroundColor: "#09617E" }}
                  className="uppercase rounded-tl-xl text-white rounded-tr-xl h-full 
                            font-normal text-base flex items-center justify-center relative"
                >
                  <h1 className="text-white font-bold">ASSIGNMENTS </h1>
                </div>
              </div>

              <div className="">
                <StudentAssignmentsTable subjectId={subjectDetails?._id} />
              </div>
            </>
          )}

          {isExam && (
            <div className="pt-8">
              <div className="h-16">
                <div
                  style={{ backgroundColor: "#09617E" }}
                  className="uppercase rounded-tl-xl text-white rounded-tr-xl h-full 
                            font-normal text-base flex items-center justify-center relative"
                >
                  <h1 className="text-white font-bold">EXAMS</h1>
                </div>
              </div>

              <div>
                <StudentExamTable subjectId={subjectDetails?._id} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default StudentEvaluation;
