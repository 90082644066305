import React, { useState } from "react";
import ParentAddEditUser from "./AddEditUser";
import TransportationStatus from "./TransportationStatus";

function ParentSwitchTransportation() {
  const [step, setStep] = useState(1);

  function switchTab() {
    switch (step) {
      case 1:
        return (
          <div>
            <TransportationStatus />
          </div>
        );

      case 2:
        return (
          <div>
            <ParentAddEditUser />
          </div>
        );

      default:
        return (
          <div>
            <TransportationStatus />
          </div>
        );
    }
  }

  return (
    <>
      <div className="p-8">
        <div className="flex gap-6 bg-white rounded-lg overflow-x ">
          <div
            onClick={() => setStep(1)}
            className={`iskool-tab-item ${
              step === 1 ? "bg-gray-600 text-white shadow text-left " : ""
            }`}
          >
            <div className="text-left">
              <div className=" text-left">Status</div>
            </div>
          </div>
          <div
            onClick={() => setStep(2)}
            className={`iskool-tab-item ${
              step === 2 ? "bg-gray-600 text-white shadow" : ""
            }`}
          >
            <div className="text-lg"> User</div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------- */}
        <div>
          <div>{switchTab()}</div>
        </div>
      </div>
    </>
  );
}

export default ParentSwitchTransportation;
