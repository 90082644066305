import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import AdminMenu from "./AdminMenu";
import Index from "./pages/Index";
import AdmissionProfile from "./pages/components/admission/AdmissionProfile";
import SwitchAdmission from "./pages/components/admission/SwitchAdmission";
import InstructorPage from "./pages/components/instructor/InstructorPage";
import SwitchInstructor from "./pages/components/instructor/SwitchInstructor";
import SwitchAttendance from "./pages/components/manageattendance/SwitchAttendance";
import ClassMain from "./pages/components/manageclass/ClassMain";
import SwitchClass from "./pages/components/manageclass/SwitchClass";
import DepartmentMain from "./pages/components/managedepartment/DepartmentMain";
import AddEditFeeTemplate from "./pages/components/managefee/AddEditTemplate";
import SwitchFee from "./pages/components/managefee/SwitchFee";
import ViewStudentFee from "./pages/components/managefee/ViewStudentFee";
import SwitchForm from "./pages/components/manageform/SwitchForm";
import ManageMessage from "./pages/components/managemessage";
import HomeWork from "./pages/components/managemessage/HomeWork";
import StudentParentList from "./pages/components/manageparent/StudentParentList";
import ViewEditStudentDetails from "./pages/components/manageparent/ViewEditStudentDetails";
import AddEditGenerate from "./pages/components/managereportcard/AddEditGenerate";
import SwitchReportCard from "./pages/components/managereportcard/SwitchReportCard";
import ViewEditProgressCard from "./pages/components/managereportcard/ViewEditProgressCard";
import ViewTemplate from "./pages/components/managereportcard/ViewTemplate";
import ViewTemplateHistory from "./pages/components/managereportcard/ViewTemplateHistory";
import SwitchSchedule from "./pages/components/manageschedule/SwitchSchedule";
import ManageStudentMain from "./pages/components/managestudent/ManageStudentMain";
import SwitchStudent from "./pages/components/managestudent/SwitchStudent";
import SubjectMain from "./pages/components/managesubject/SubjectMain";
import SwitchSubject from "./pages/components/managesubject/SwitchSubject";
import SwitchTransportation from "./pages/components/managetransportation/SwitchTransportation";
import ParentHomePage from "./pages/components/parent/ParentHomePage";
import HelpPage from "./pages/help/HelpPage";
import ManageAssignment from "./pages/components/manageassignment/ManageAssignment";
import ManageExam from "./pages/components/manageexam/ManageExam";

const AdminRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/admin`}>
        <Switch>
          <Route exact path={`${url}/admin/menu`}>
            <AdminMenu />
          </Route>
          <Route exact path={`${url}/admin/menu/instructor`}>
            <InstructorPage />
          </Route>
          <Route exact path={`${url}/admin/menu/instructor/profile/:id`}>
            <SwitchInstructor />
          </Route>
          <Route exact path={`${url}/admin/menu/admission`}>
            <SwitchAdmission />
          </Route>
          <Route exact path={`${url}/admin/menu/admission/:id`}>
            <AdmissionProfile />
          </Route>
          <Route exact path={`${url}/admin/parent`}>
            <ParentHomePage />
          </Route>
          <Route exact path={`${url}/admin/menu/student`}>
            <ManageStudentMain />
          </Route>
          <Route exact path={`${url}/admin/menu/student/profile/:id`}>
            <SwitchStudent />
          </Route>
          <Route exact path={`${url}/admin/menu/class`}>
            <ClassMain />
          </Route>
          <Route exact path={`${url}/admin/menu/class/profile/:id`}>
            <SwitchClass />
          </Route>
          <Route exact path={`${url}/admin/menu/subject`}>
            <SubjectMain />
          </Route>
          <Route exact path={`${url}/admin/menu/subject/profile/:id`}>
            <SwitchSubject />
          </Route>
          <Route exact path={`${url}/admin/menu/department`}>
            <DepartmentMain />
          </Route>
          <Route exact path={`${url}/admin/menu/fee/:tab`}>
            <SwitchFee />
          </Route>
          <Route exact path={`${url}/admin/menu/class/:id/student/:id`}>
            <ViewStudentFee />
          </Route>
          <Route exact path={`${url}/admin/menu/fee/template/:id`}>
            <AddEditFeeTemplate />
          </Route>
          <Route exact path={`${url}/admin/menu/attendance`}>
            <SwitchAttendance />
          </Route>
          <Route exact path={`${url}/admin/menu/parent`}>
            <StudentParentList />
          </Route>
          <Route exact path={`${url}/admin/menu/parent/:id`}>
            <ViewEditStudentDetails />
          </Route>
          <Route exact path={`${url}/admin/menu/report/`}>
            <SwitchReportCard />
          </Route>
          <Route exact path={`${url}/admin/menu/report-template/:id`}>
            <ViewTemplate />
          </Route>
          <Route exact path={`${url}/admin/menu/student/:id/template/:id`}>
            <AddEditGenerate />
          </Route>
          <Route exact path={`${url}/admin/menu/student/template-history/:id`}>
            <ViewTemplateHistory />
          </Route>
          <Route
            exact
            path={`${url}/admin/menu/student/:id/template/:id/report/:id/`}
          >
            <ViewEditProgressCard />
          </Route>
          <Route exact path={`${url}/admin/menu/form`}>
            <SwitchForm />
          </Route>
          <Route exact path={`${url}/admin/menu/home-work`}>
            <HomeWork />
          </Route>
          <Route exact path={`${url}/admin/menu/message`}>
            <ManageMessage />
          </Route>
          <Route exact path={`${url}/admin/menu/schedule/`}>
            <SwitchSchedule />
          </Route>
          <Route exact path={`${url}/admin/menu/transportation/`}>
            <SwitchTransportation />
          </Route>
          <Route exact path={`${url}/admin/menu/assignment/`}>
            <ManageAssignment />
          </Route>
          <Route exact path={`${url}/admin/menu/exam/`}>
            <ManageExam />
          </Route>
          <Route exact path={`${url}/admin/help`}>
            <HelpPage />
          </Route>
          <Route path={`${url}/admin/home`} component={Index} />
        </Switch>
      </Route>
      <Route exact path={url}>
        <Redirect to={`${url}/admin/home`} />
      </Route>
    </Switch>
  );
};

export default AdminRoutes;
